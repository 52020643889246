/* eslint-disable default-param-last */
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import tzlookup from 'tz-lookup';
import { ForbiddenError, NotFoundError } from '@avtjs/avt-services';
import { v4 as uuidv4 } from 'uuid';

import { downloadBlob } from '../utils';
import {
  getAllSitesData,
  getModelThumbnail as getModelThumbnailApi,
  createSite as createSiteApi,
  addLayout as addLayoutApi,
  updateSite as updateSiteApi,
  deleteSite as deleteSiteApi,
  uploadSitesExcel as uploadSitesExcelApi,
  downloadSitesExcelTemplate as downloadSitesExcelTemplateApi,
  lookupSite as lookupSiteApi,
  syncRemoteAssets,
  createIntegration as createIntegrationApi,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import { requestComponents } from './components';
import getNotification from './notification-defaults';
import { setTimezone, CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const GET_SITES = 'dt/sites/GET_SITES';
export const SET_SITES = 'dt/sites/SET_SITES';

const CREATE_SITE = 'dt/sites/CREATE_SITE';
const SET_CREATED_SITE = 'dt/sites/SET_CREATED_SITE';
const SET_CREATE_PROJECT = 'dt/sites/SET_CREATE_PROJECT';
const SET_PROJECTS = 'dt/sites/SET_PROJECTS';
const SET_ACTIVE_SITE = 'dt/sites/SET_ACTIVE_SITE';
const SET_ACTIVE_SITE_ID = 'dt/sites/SET_ACTIVE_SITE_ID';
const SET_ACTIVE_SITE_UIMODE = 'dt/sites/SET_ACTIVE_SITE_UIMODE';
const UPDATE_SITE = 'dt/sites/UPDATE_SITE';
const DELETE_SITE = 'dt/sites/DELETE_SITE';
export const REMOVE_SITE = 'dt/sites/REMOVE_SITE';

const DOWNLOAD_SITES_TEMPLATE = 'dt/sites/DOWNLOAD_SITES_TEMPLATE';
const UPLOAD_SITES_EXCEL_SHEET = 'dt/sites/UPLOAD_SITES_EXCEL_SHEET';

const REQUEST_MODEL_THUMBNAIL = 'dt/models/REQUEST_MODEL_THUMBNAIL';
const RECEIVE_MODEL_THUMBNAIL = 'dt/models/RECEIVE_MODEL_THUMBNAIL';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestSites = (callback) => ({
  type: GET_SITES,
  callback,
});

export const requestModelThumbnail = (modelId, versionId) => ({
  type: REQUEST_MODEL_THUMBNAIL,
  modelId,
  versionId,
});

export const receiveModelThumbnail = (modelId, versionId, src) => ({
  type: RECEIVE_MODEL_THUMBNAIL,
  modelId,
  versionId,
  src,
});

export const setSites = (sites) => ({
  type: SET_SITES,
  sites,
});

export const createSite = (site, layout, callback) => ({
  type: CREATE_SITE,
  site,
  layout,
  callback,
});

export const setProjects = (projects) => ({
  type: SET_PROJECTS,
  projects,
});

export const createProject = (id, projectName, callback) => ({
  type: CREATE_PROJECT,
  id,
  projectName,
  callback,
});

export const setCreatedProject = (createdProject) => ({
  type: SET_CREATE_PROJECT,
  createdProject,
});

export const setCreatedSite = (createdSite) => ({
  type: SET_CREATED_SITE,
  createdSite,
});

export const setActiveSite = (siteId) => ({
  type: SET_ACTIVE_SITE,
  siteId,
});

export const setActiveSiteId = (siteId) => ({
  type: SET_ACTIVE_SITE_ID,
  siteId,
});

export const setActiveUIMode = (uiMode) => ({
  type: SET_ACTIVE_SITE_UIMODE,
  uiMode,
});

export const updateSite = (siteId, site, callback) => ({
  type: UPDATE_SITE,
  siteId,
  site,
  callback,
});

export const deleteSite = (siteId) => ({
  type: DELETE_SITE,
  siteId,
});

export const removeSite = (siteId) => ({
  type: REMOVE_SITE,
  siteId,
});

export const downloadSitesExcel = () => ({
  type: DOWNLOAD_SITES_TEMPLATE,
});

export const uploadSitesExcelFile = (file, realm) => ({
  type: UPLOAD_SITES_EXCEL_SHEET,
  file,
  realm,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  sites: [],
  projects: [],
  thumbnails: {},
  createdSite: null,
  activeSiteId: null,
  activeUiMode: 'Identiq',
  siteSetAt: null,
  sitesLoadedAt: null,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SITES: {
      const { sites } = action;
      return {
        ...state,
        sites: sites.map(({ layouts = [], ...rest }) => ({
          ...rest,
          layout:
            layouts.length > 0
              ? layouts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
              : {},
        })),
        sitesLoadedAt: Date.now(),
      };
    }
    case RECEIVE_MODEL_THUMBNAIL: {
      const { modelId, versionId, src } = action;
      const thumbnailKey = `${modelId}/${versionId}`;

      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [thumbnailKey]: src,
        },
      };
    }
    case SET_CREATED_SITE: {
      return { ...state, createdSite: action.createdSite };
    }

    case SET_ACTIVE_SITE_ID: {
      return {
        ...state,
        activeSiteId: action.siteId,
        siteSetAt: Date.now(),
      };
    }
    case SET_ACTIVE_SITE_UIMODE: {
      return { ...state, activeUiMode: action.uiMode };
    }
    case REMOVE_SITE: {
      return {
        ...state,
        sites: state.sites.filter((s) => s.id !== action.siteId),
      };
    }
    case CLEAR_SITE_DATA: {
      return {
        ...state,
        createdSite: null,
        createdProjects: null,
        activeSiteId: null,
        activeUiMode: 'Identiq',
        siteSetAt: null,
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

export const lookupSite = async (type, thirdpartyId, query) => {
  try {
    const data = await lookupSiteApi(type, thirdpartyId, query);
    return data;
  } catch (e) {
    console.error('Unable to lookup site: ', e);
    throw new Error(e);
  }
};

export const getModelVersion = (model, activeSite) => {
  if (activeSite.version) return activeSite.version;

  const completedVersions = model.versions
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((v) => v.status === 'COMPLETED');

  return completedVersions[0].id;
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSites = (state) => state.sites.sites;

export const getProjects = (test) => test.projectName;

export const getSiteSetAt = (state) => state.sites.siteSetAt;

export const getSite = createSelector(
  getSites,
  (_, siteId) => siteId,
  (sites, siteId) => sites.find((site) => site.id === siteId)
);

export const getCreatedSite = (state) => state.sites.createdSite;

export const getActiveSiteId = (state) => state.sites.activeSiteId;

export const getThumbnails = (state) => state.sites.thumbnails;

export const getModelThumbnail = createSelector(
  getThumbnails,
  (_, modelId, versionId) => [modelId, versionId],
  (thumbnails, [modelId, versionId]) => {
    if (!modelId || !versionId) return null;

    const thumbnailKey = `${modelId}/${versionId}`;
    if (typeof thumbnails[thumbnailKey] === 'undefined') return null;

    return thumbnails[thumbnailKey];
  }
);

export const getActiveSite = createSelector([getActiveSiteId, getSites], (activeSiteId, sites) => {
  if (sites.length && activeSiteId) {
    return sites.find((site) => site.id === activeSiteId);
  }
  // return empty site obj if waiting on site data to diff from non-site
  return {};
});

export const getActiveUiMode = (state) => state.sites.activeUiMode;

export const getSitesLoaded = (state) => state.sites.sitesLoadedAt;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doSetActiveSite({ siteId }) {
  try {
    const site = yield select((state) => getSite(state, siteId));
    if (site) {
      const {
        location: { lat, lng },
      } = site;
      let tz = 'Europe/Stockholm';
      try {
        tz = tzlookup(lat, lng);
      } catch (e) {
        console.error(e);
      }
      yield put(setTimezone(tz));
      yield put(setActiveUIMode(site.uiMode));
      yield put(setActiveSiteId(site.id));
    }
  } catch (e) {
    console.error('Unable to set active site: ', e);
    yield call(checkOnline);
  }
}

function* doRequestSitesData({ callback = () => null }) {
  try {
    const { values } = yield call(getAllSitesData);
    const sites = values.filter(({ location }) => !!location);
    yield put(setSites(sites));
  } catch (e) {
    console.error('Unable to fetch site data: ', e);
    yield call(checkOnline);
    yield put(setSites([]));
    if (e instanceof ForbiddenError) {
      if (callback) {
        yield callback();
      }
      return;
    }
    yield put(displayNotification(getNotification('getSites', 'error')()));
  }

  if (callback) {
    yield callback();
  }
}

function* doRequestModelThumbnail({ modelId, versionId }) {
  try {
    const data = yield call(getModelThumbnailApi, modelId, versionId);
    const reader = new FileReader();
    const src = yield new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(data);
    });
    yield put(receiveModelThumbnail(modelId, versionId, src));
  } catch (e) {
    if (!(e instanceof NotFoundError)) {
      yield call(checkOnline);
      console.error('Unable to fetch model thumbnail: ', e);
    }
    yield put(receiveModelThumbnail(modelId, versionId, null));
  }
}

function* doCreateSite(action) {
  const { site, layout, callback } = action;
  try {
    const createdSite = yield call(createSiteApi, site);
    if (createdSite) {
      yield call(addLayoutApi, createdSite.id, layout);
      const { values } = yield call(getAllSitesData);
      const sites = values.filter(({ location }) => !!location);
      yield put(setSites(sites));
      yield put(setCreatedSite(createdSite));

      if (createdSite.properties) {
        const integrationTypeProp = createdSite.properties.find(
          (prop) => prop.name === 'integration'
        )?.value;
        const { integrationType, thirdPartyId } = integrationTypeProp;

        if (integrationTypeProp) {
          const createIntegrationPayload = {
            name: 'Lumada',
            type: integrationType,
            // we add random uuid here since it's required on non-templates
            templateId: uuidv4(),
            main: true,
            org: createdSite.org,
            site: createdSite.id,
            configuration: {
              asset: {
                rootAssetId: thirdPartyId,
                // syncEnabled: true,
              },
              deeplinks: {
                siteLink: { enabled: false, endpoint: '' },
                componentLink: { enabled: false, endpoint: '' },
              },
            },
          };
          const createdIntegration = yield call(createIntegrationApi, createIntegrationPayload);
          if (createdIntegration) {
            yield call(syncRemoteAssets, createdIntegration.id, { startDate: 0 });
            yield put(requestComponents(createdSite.id));
          }
        }
      }
    }
  } catch (e) {
    console.error('Encountered an error while creating site: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createSite', 'error')()));
  }
  callback();
}

function* doUpdateSite(action) {
  const { siteId, site, callback = () => null } = action;
  try {
    const updatedSite = yield call(updateSiteApi, siteId, site);
    yield put(displayNotification(getNotification('updateSite', 'success')(siteId)));
    if (updatedSite) {
      const { values: sites } = yield call(getAllSitesData);
      yield put(setSites(sites));
      if (callback) {
        yield callback();
      }
    }
  } catch (e) {
    console.error('Unable to update site: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateSite', 'error')(siteId)));
  }
}

function* doDeleteSite(action) {
  const { siteId } = action;
  try {
    yield call(deleteSiteApi, siteId);
    yield put(removeSite(siteId));
    yield put(displayNotification(getNotification('deleteSite', 'success')(siteId)));
  } catch (e) {
    console.error('Unable to delete site: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteSite', 'error')(siteId)));
  }
}

function* doUploadSitesExcel(action) {
  const { file, realm } = action;
  try {
    const fd = new FormData();
    fd.append('sites', file);
    fd.append('org', realm);
    yield call(uploadSitesExcelApi, fd);
    yield put(requestSites());
    yield put(displayNotification(getNotification('importSites', 'success')()));
  } catch (e) {
    console.error('Unable to import sites: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('importSites', 'error')()));
  }
}

function* doDownloadSitesTemplate() {
  try {
    const file = yield call(downloadSitesExcelTemplateApi);
    downloadBlob(file.data, 'sites_config_template.xlsx', file.headers['content-type']);
  } catch (e) {
    console.error('Unable to download sites template: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('exportSites', 'error')()));
  }
}

export const sagas = [
  takeLatest(GET_SITES, doRequestSitesData),
  takeLatest(SET_ACTIVE_SITE, doSetActiveSite),
  takeLatest(CREATE_SITE, doCreateSite),
  takeLatest(UPDATE_SITE, doUpdateSite),
  takeLatest(DELETE_SITE, doDeleteSite),
  takeLatest(DOWNLOAD_SITES_TEMPLATE, doDownloadSitesTemplate),
  takeLatest(UPLOAD_SITES_EXCEL_SHEET, doUploadSitesExcel),
  takeEvery(REQUEST_MODEL_THUMBNAIL, doRequestModelThumbnail),
];
