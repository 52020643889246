/* eslint-disable default-param-last */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import {
  getIntegrations as getIntegrationsApi,
  getIntegrationTypes as getIntegrationTypesApi,
  createIntegration as createIntegrationApi,
  createIntegrationTemplate as createIntegrationTemplateApi,
  updateIntegration as updateIntegrationApi,
  updateIntegrationTemplate as updateIntegrationTemplateApi,
  deleteIntegration as deleteIntegrationApi,
  syncRemoteAssets as syncRemoteAssetsApi,
  createIntegratedSource,
  getIntegratedSources,
  updateIntegratedSource,
  importSource,
  createRemoteEvent as createRemoteEventApi,
} from '../services';
import { getActiveSite } from './sites';
import { getImportableSourceTypes, requestSources } from './sources';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import { requestComponents } from './components';
import { cleanFormData } from '../utils';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_INTEGRATIONS = 'dt/integrations/REQUEST_INTEGRATIONS';
const CREATE_INTEGRATION = 'dt/integrations/CREATE_INTEGRATION';
const CREATE_INTEGRATION_TEMPLATE = 'dt/integrations/CREATE_INTEGRATION_TEMPLATE';
const UPDATE_INTEGRATION = 'dt/integrations/UPDATE_INTEGRATION';
const UPDATE_INTEGRATION_TEMPLATE = 'dt/integrations/UPDATE_INTEGRATION_TEMPLATE';
const DELETE_INTEGRATION = 'dt/integrations/DELETE_INTEGRATION';
const RECEIVE_INTEGRATIONS = 'dt/integrations/RECEIVE_INTEGRATIONS';
const RECEIVE_INTEGRATION_TEMPLATES = 'dt/integrations/RECEIVE_INTEGRATION_TEMPLATES';
const RECEIVE_INTEGRATION = 'dt/integrations/RECEIVE_INTEGRATION';
const REMOVE_INTEGRATION = 'dt/integrations/REMOVE_INTEGRATION';
const SYNC_REMOTE_ASSETS = 'dt/integrations/SYNC_REMOTE_ASSETS';
const CREATE_REMOTE_EVENT = 'dt/integrations/CREATE_REMOTE_EVENT';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestIntegrations = (query) => ({
  type: REQUEST_INTEGRATIONS,
  query,
});

export const createIntegration = (data) => ({
  type: CREATE_INTEGRATION,
  data,
});

export const createIntegrationTemplate = (data) => ({
  type: CREATE_INTEGRATION_TEMPLATE,
  data,
});

export const updateIntegration = (id, data) => ({
  type: UPDATE_INTEGRATION,
  id,
  data,
});

export const updateIntegrationTemplate = (id, data) => ({
  type: UPDATE_INTEGRATION_TEMPLATE,
  id,
  data,
});

export const deleteIntegration = (id) => ({
  type: DELETE_INTEGRATION,
  id,
});

export const receiveIntegrations = (integrations, integrationTypes, loadedScope) => ({
  type: RECEIVE_INTEGRATIONS,
  integrations,
  integrationTypes,
  loadedScope,
});

export const receiveIntegrationTemplates = (templates) => ({
  type: RECEIVE_INTEGRATION_TEMPLATES,
  templates,
});

export const receiveIntegration = (integration) => ({
  type: RECEIVE_INTEGRATION,
  integration,
});

export const removeIntegration = (id) => ({
  type: REMOVE_INTEGRATION,
  id,
});

export const syncRemoteAssets = (integrationId, siteId, callback = () => null) => ({
  type: SYNC_REMOTE_ASSETS,
  integrationId,
  siteId,
  callback,
});

export const createRemoteEvent = (integrationType, data) => ({
  type: CREATE_REMOTE_EVENT,
  integrationType,
  data,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  integrationTypes: [],
  integrations: [],
  loadedScope: undefined, // either site id or org
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_INTEGRATIONS: {
      return {
        ...state,
        integrationTypes: action.integrationTypes,
        integrations: action.integrations,
        loadedScope: action.loadedScope,
      };
    }
    case RECEIVE_INTEGRATION_TEMPLATES: {
      return {
        ...state,
        templates: action.templates,
      };
    }
    case RECEIVE_INTEGRATION: {
      const { integration } = action;
      const integrationIndex = state.integrations.findIndex((i) => i.id === integration.id);
      if (integrationIndex < 0) {
        return { ...state, integrations: [...state.integrations, integration] };
      }
      return {
        ...state,
        integrations: [
          ...state.integrations.slice(0, integrationIndex),
          integration,
          ...state.integrations.slice(integrationIndex + 1),
        ],
      };
    }
    case REMOVE_INTEGRATION: {
      const { id } = action;
      const integrationIndex = state.integrations.findIndex((i) => i.id === id);
      if (integrationIndex >= 0) {
        return {
          ...state,
          integrations: [
            ...state.integrations.slice(0, integrationIndex),
            ...state.integrations.slice(integrationIndex + 1),
          ],
        };
      }
      return state;
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        integrationTypes: [],
        integrations: [],
        templates: [],
        loadedScope: undefined, // either site id or org
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

const formatDateTime = (date, time) => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const seconds = time.split(':')[2];

  const formattedDate = new Date(date).setHours(hours, minutes, seconds);
  return new Date(formattedDate);
};

export const formatLumadaWorkOrder = (workOrder, assetUUID, locationUUID) => {
  const { fromDate, fromTime, toDate, toTime } = workOrder;
  const plannedStartDateTime =
    fromDate && fromTime ? formatDateTime(workOrder.fromDate, workOrder.fromTime) : undefined;
  const plannedFinishDateTime =
    toDate && toTime ? formatDateTime(workOrder.toDate, workOrder.toTime) : undefined;

  const payload = {
    asset: assetUUID,
    location: locationUUID,
    description: workOrder.name || workOrder.title,
    extendedDescription: workOrder.description,
    initiationType: workOrder.initiationType || 'RequestForWork',
    workOrderId: `WR${new Date().getTime()}`,
    userStatus: workOrder.userStatus,
    assetWork: true,
    priority: workOrder.priority,
    workType: 'Corrective',
    status: 'Potential',
    workOrderUUID: uuidv4(),
  };

  if (workOrder.requiredByDateTime) payload.requiredByDateTime = workOrder.requiredByDateTime;
  if (plannedFinishDateTime) payload.plannedFinishDateTime = plannedFinishDateTime;
  if (plannedStartDateTime) payload.plannedStartDateTime = plannedStartDateTime;

  return payload;
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getIntegrationTypes = (state) => state.integrations.integrationTypes;

export const getIntegrationsLoaded = (state) => state.integrations.loadedScope;

export const getAllIntegrations = (state) => state.integrations.integrations;

export const getTemplates = createSelector(getAllIntegrations, (integrations) =>
  integrations.filter(({ site }) => !site)
);

export const getSiteIntegrations = createSelector(
  getActiveSite,
  getAllIntegrations,
  ({ id }, integrations) => {
    if (id) {
      return integrations.filter(({ site }) => site === id);
    }
    return [];
  }
);

export const getIntegrations = createSelector(getAllIntegrations, (integrations) =>
  integrations.filter(({ templateId, site }) => templateId && site)
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestIntegrations(action) {
  const { query } = action;
  try {
    const { values: integrationTypes } = yield call(getIntegrationTypesApi);
    const { values } = yield call(getIntegrationsApi, query);
    yield put(receiveIntegrations(values, integrationTypes, query.site || query.org));
  } catch (e) {
    console.error('Unable to fetch integrations: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getIntegrations', 'error')()));
  }
}

function* doSyncRemoteAssets(action) {
  const { integrationId, siteId, callback } = action;
  try {
    const { added, deleted, updated } = yield call(syncRemoteAssetsApi, integrationId);
    const message = `Synced assets: added (${added}), updated (${updated}), deleted (${deleted}).`;
    yield put(
      displayNotification(getNotification('syncAssets', 'success')(message, integrationId))
    );
    yield put(requestComponents(siteId));
    callback();
  } catch (e) {
    console.error('Unable to sync integrated assets: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('syncAssets', 'error')(integrationId)));
  }
}

function* doCreateIntegration(action) {
  const { data: createIntegrationPayload } = action;
  try {
    const createdIntegration = yield call(createIntegrationApi, createIntegrationPayload);
    const message = [];
    if (createdIntegration) {
      message.push(`Created integration: ${createdIntegration.name}`);
      yield put(receiveIntegration(createdIntegration));

      const {
        id,
        site,
        org,
        type,
        name,
        configuration: { connection, data } = {},
      } = createdIntegration;
      const importableSourceTypes = yield select(getImportableSourceTypes);
      const isImportable = importableSourceTypes.includes(type);

      // check for brokered, asset-type integration
      if (createdIntegration.configuration?.asset && createdIntegration.type !== 'custom') {
        try {
          const { added, updated, deleted } = yield call(
            syncRemoteAssetsApi,
            createdIntegration.id,
            { startDate: 0 }
          );
          message.push(
            `Synced assets: added (${added}), updated (${updated}), deleted (${deleted}).`
          );
        } catch (e) {
          console.error('Unable to sync integrated assets: ', e);
          yield call(checkOnline);
          yield put(displayNotification(getNotification('syncAssets', 'error')(id)));
          message.push('*** Asset sync failed ***');
        }
      }

      // check for defined or importable data-type integration
      if (connection && (data || (type !== 'custom' && isImportable))) {
        try {
          const sourcePayload = cleanFormData({
            options: {
              ...connection,
              ...data,
            },
            name: `${name} source`,
            org,
            site,
            integration: id,
          });
          const source = yield call(createIntegratedSource, id, sourcePayload);
          message.push(`Created integrated source: ${source.name}`);

          const shouldImportSignals =
            (type !== 'custom' && isImportable) ||
            (data?.import?.enabled && data?.import?.endpoint);
          if (source && shouldImportSignals) {
            try {
              const { added, updated } = yield call(importSource, source.id);
              if (added || updated) {
                message.push(`Imported source signals: added (${added}), updated (${updated})`);
              }
            } catch (e) {
              console.error('Unable to import integrated source signals: ', e);
              yield call(checkOnline);
              yield put(
                displayNotification(getNotification('importSourceVars', 'error')(e.message))
              );
              message.push('*** Data source signal import failed ***');
            }
          }

          yield put(requestSources({ siteId: site }));
        } catch (e) {
          console.error('Unable to create integrated source: ', e);
          yield call(checkOnline);
          yield put(displayNotification(getNotification('createSource', 'error')(e.message)));
          message.push('*** Data source creation failed ***');
        }
      }
      yield put(displayNotification(getNotification('createIntegration', 'success')(message, id)));
    }
  } catch (e) {
    console.error('Encountered errors while creating integration: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('createIntegration', 'error')(createIntegrationPayload.name)
      )
    );
  }
}

function* doCreateIntegrationTemplate(action) {
  const { data } = action;
  try {
    const createdTemplate = yield call(createIntegrationTemplateApi, data);
    if (createdTemplate) {
      yield put(
        displayNotification(
          getNotification('createIntegration', 'success')(data.name, createdTemplate.id, true)
        )
      );
      yield put(receiveIntegration(createdTemplate));
    }
  } catch (e) {
    console.error('Unable to create integration: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createIntegration', 'error')(data.name)));
  }
}

function* doUpdateIntegration(action) {
  const { id, data: updateIntegrationPayload } = action;
  try {
    const updatedIntegration = yield call(updateIntegrationApi, id, updateIntegrationPayload);
    const message = [];
    if (updatedIntegration) {
      message.push(`Updated integration: ${updatedIntegration.name}`);
      yield put(receiveIntegration(updatedIntegration));

      const {
        site,
        org,
        type,
        name,
        configuration: { connection, data } = {},
      } = updatedIntegration;
      const importableSourceTypes = yield select(getImportableSourceTypes);
      const isImportable = importableSourceTypes.includes(type);

      // check for brokered, asset-type integration
      if (updatedIntegration.configuration?.asset && updatedIntegration.type !== 'custom') {
        try {
          const { added, updated, deleted } = yield call(
            syncRemoteAssetsApi,
            updatedIntegration.id
          );
          message.push(
            `Synced assets: added (${added}), updated (${updated}), deleted (${deleted}).`
          );
        } catch (e) {
          console.error('Unable to sync integrated assets: ', e);
          yield call(checkOnline);
          yield put(displayNotification(getNotification('syncAssets', 'error')(id)));
          message.push('*** Asset sync failed ***');
        }
      }

      // check for defined or importable data-type integration
      if (connection && (data || (type !== 'custom' && isImportable))) {
        try {
          const { values: [integratedSource] = [] } = yield call(getIntegratedSources, id, { org });
          const sourcePayload = cleanFormData({
            options: {
              ...connection,
              ...data,
            },
            name: `${name} source`,
            org,
            site,
            integration: id,
          });
          let updatedSource;
          if (integratedSource) {
            updatedSource = yield call(
              updateIntegratedSource,
              id,
              integratedSource.id,
              sourcePayload
            );
          } else {
            updatedSource = yield call(createIntegratedSource, id, sourcePayload);
          }
          message.push(`Updated integrated source: ${updatedSource.name}`);

          const shouldImportSignals =
            (type !== 'custom' && isImportable) ||
            (data?.import?.enabled && data?.import?.endpoint);
          if (updatedSource && shouldImportSignals) {
            try {
              const { added, updated } = yield call(importSource, updatedSource.id);
              if (added || updated) {
                message.push(`Imported source signals: added (${added}), updated (${updated})`);
              }
            } catch (e) {
              console.error('Unable to import integrated source signals: ', e);
              yield call(checkOnline);
              yield put(
                displayNotification(getNotification('importSourceVars', 'error')(e.message))
              );
              message.push('*** Data source signal import failed ***');
            }
          }

          yield put(requestSources({ siteId: site }));
        } catch (e) {
          console.error('Unable to create/update integrated source: ', e);
          yield call(checkOnline);
          yield put(
            displayNotification(getNotification('updateSource', 'error')(updatedIntegration.id))
          );
          message.push('*** Data source update failed ***');
        }
      }
      yield put(displayNotification(getNotification('updateIntegration', 'success')(message, id)));
    }
  } catch (e) {
    console.error('Encountered errors while updating integration: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('updateIntegration', 'error')(updateIntegrationPayload.name, id)
      )
    );
  }
}

function* doUpdateIntegrationTemplate(action) {
  const { id, data } = action;
  try {
    const updatedIntegration = yield call(updateIntegrationTemplateApi, id, data);
    if (updatedIntegration) {
      yield put(
        displayNotification(getNotification('updateIntegration', 'success')(data.name, id, true))
      );
      yield put(receiveIntegration(updatedIntegration));
    }
  } catch (e) {
    console.error('Unable to update integration: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateIntegration', 'error')(data.name, id)));
  }
}

function* doDeleteIntegration(action) {
  const { id } = action;
  try {
    yield call(deleteIntegrationApi, id);
    yield put(removeIntegration(id));
    yield put(displayNotification(getNotification('deleteIntegration', 'success')(id)));
  } catch (e) {
    console.error('Unable to delete integration: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteIntegration', 'error')(id)));
  }
}

function* doCreateRemoteEvent(action) {
  const { integrationType, data } = action;
  const integrations = yield select(getSiteIntegrations);
  const integration = integrations.find((i) => i.type === integrationType);
  if (integration) {
    try {
      const { entityUUID: remoteEventId } = yield call(createRemoteEventApi, integration.id, data);
      if (remoteEventId) {
        yield put(
          displayNotification(
            getNotification('createRemoteEvent', 'success')(data.name, remoteEventId)
          )
        );
      }
    } catch (e) {
      console.error('Unable to create remote event: ', e);
      yield call(checkOnline);
      yield put(displayNotification(getNotification('createRemoteEvent', 'error')(data.name)));
    }
  } else {
    yield put(displayNotification(getNotification('createRemoteEvent', 'error')(data.name)));
  }
}

export const sagas = [
  takeLatest(REQUEST_INTEGRATIONS, doRequestIntegrations),
  takeLatest(CREATE_INTEGRATION, doCreateIntegration),
  takeLatest(CREATE_INTEGRATION_TEMPLATE, doCreateIntegrationTemplate),
  takeLatest(UPDATE_INTEGRATION, doUpdateIntegration),
  takeLatest(UPDATE_INTEGRATION_TEMPLATE, doUpdateIntegrationTemplate),
  takeLatest(DELETE_INTEGRATION, doDeleteIntegration),
  takeLatest(SYNC_REMOTE_ASSETS, doSyncRemoteAssets),
  takeLatest(CREATE_REMOTE_EVENT, doCreateRemoteEvent),
];
