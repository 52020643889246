import React from 'react';
import SelectPollingDateRange from '../../SelectPollingDateRange/SelectPollingDateRange';
import './DateTimePickerField.scss';

const DateTimePickerField = ({ schema: { title, panelId } }) => {
  return (
    <div className={`date-time-picker`}>
      <label>{`${title}*`}</label>
      <SelectPollingDateRange
        panelId={panelId}
        enableGlobalDates={false}
      />
    </div>
  );
};

export default DateTimePickerField;
