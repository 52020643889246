import React from 'react';
import SimpleModal from '../../../SimpleModal';
import JSONEditor from '../../../JSONEditor';
import { getSiteSchema, siteUISchema } from './schemas/siteSchema';

const SiteCreateModal = ({
  siteParams,
  layouts = [],
  onSubmit,
  onCloseModal,
  transformErrors,
  children,
}) => {
  const schema = getSiteSchema(layouts);
  const createFields =
    siteParams && Object.keys(siteParams).length > 0
      ? {
          name: siteParams.name,
          customer: siteParams.customer,
          org: siteParams.org,
          location: {
            city: siteParams.city,
            country: siteParams.country,
            lat: parseInt(siteParams.lat, 10),
            lng: parseInt(siteParams.lng, 10),
          },
        }
      : {};

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Add project"
      overlayCanClose={false}
    >
      <div className="create-site-content">
        <JSONEditor
          title="Project"
          formData={createFields}
          showErrorList={false}
          schema={schema}
          uiSchema={siteUISchema}
          initialEditMode={true}
          showEditButton={false}
          saveButtonText={'Create'}
          cancelCallback={onCloseModal}
          onFormSubmit={({ formData }) => onSubmit(formData)}
          customTransformErrors={(errors) => transformErrors(errors)}
        />
        {children}
      </div>
    </SimpleModal>
  );
};

export default SiteCreateModal;
