import React, { useState, useCallback, useEffect, useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { useSelector } from 'react-redux';
import { Spinner, Icon, useAuth } from '@avtjs/react-components';

import { getMembers } from '../../bundles/auth';
import RoleSelect from '../RoleSelect';

const PermissionLegend = ({ selectedMember }) => {
  const { realm } = useAuth();
  const orgMembers = useSelector((state) => getMembers(state, `org/${realm}`));
  const [selectedRole, setSelectedRole] = useState(selectedMember);

  useEffect(() => {
    if (!selectedMember && orgMembers) {
      setSelectedRole(orgMembers.find((member) => member.type === 'Viewer'));
    }
  }, [selectedMember, orgMembers]);

  const onRoleSelect = useCallback(
    (memberType) => setSelectedRole(orgMembers.find((member) => member.type === memberType)),
    [orgMembers]
  );

  const permissionsByType = useMemo(
    () =>
      (selectedRole?.permissions || []).reduce((acc, permission) => {
        const bits = permission.split('/');
        if (bits[0] !== 'projects') {
          return {
            ...acc,
            [bits[0]]: [bits[1], ...(acc[bits[0]] || [])],
          };
        }
        return acc;
      }, {}),
    [selectedRole]
  );

  if (!orgMembers || !selectedRole) {
    return <Spinner />;
  }

  return (
    <div className={'permission-legend-component'}>
      {!selectedMember && (
        <RoleSelect
          isMulti={false}
          value={selectedRole.type}
          realm={realm}
          onChange={onRoleSelect}
          hideAllRoles
        />
      )}

      <div className="permissions-table">
        <table>
          <thead>
            <tr>
              <th />
              <th>Read</th>
              <th>Write</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(permissionsByType)
              .sort(([entityA], [entityB]) => entityA.localeCompare(entityB))
              .map(([entity, permissionList]) => (
                <tr key={entity}>
                  <td>{entity}</td>
                  <td>
                    <Icon icon={permissionList.includes('Read') ? 'check' : 'blank'} />
                  </td>
                  <td>
                    <Icon icon={permissionList.includes('Write') ? 'check' : 'blank'} />
                  </td>
                  <td>
                    <Icon icon={permissionList.includes('Delete') ? 'check' : 'blank'} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PermissionLegend;
