import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOnline, checkIsOnline } from '../../bundles/notifications';

const SiteThumbnail = ({ src, name }) => {
  const dispatch = useDispatch();
  const online = useSelector(getOnline);
  const [thumbnailError, setThumbnailError] = useState(false);

  const handleImgError = () => {
    setThumbnailError(true);
    dispatch(checkIsOnline());
  };

  return (
    <div className="site-thumbnail-component">
      {!src || !online || thumbnailError ? (
        <div className="thumbnail-placeholder">{name && name[0]}</div>
      ) : (
        <img
          src={src}
          onError={handleImgError}
          alt=""
        />
      )}
    </div>
  );
};

export default SiteThumbnail;
