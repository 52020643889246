.date-time-picker {
  /*Timerange widget for Export signals popup*/
}
.date-time-picker .select-period-component .header {
  background-color: #fff;
  min-height: 38px;
  width: 100%;
  justify-content: flex-start !important;
}
.date-time-picker .select-period-component .header:hover {
  border-color: #b3b3b3 !important;
}
.date-time-picker .select-period-component .header .label {
  display: flex;
  flex: 1;
  border-right: 1.5px solid #cacaca;
  height: 21px;
  align-items: center;
}
.date-time-picker .select-period-component .header > div:last-child {
  margin-left: 8px !important;
  margin-right: 0;
  position: relative;
  color: #cccccc !important;
}
.date-time-picker .select-period-component .header > div:last-child svg {
  transform: scale(1.4);
}
.date-time-picker .select-period-component .header > div:last-child :hover {
  color: #999999;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvSlNPTkVkaXRvci9maWVsZHMiLCJzb3VyY2VzIjpbIkRhdGVUaW1lUGlja2VyRmllbGQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtBQUNFOztBQUdFO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZGF0ZS10aW1lLXBpY2tlciB7XG4gIC8qVGltZXJhbmdlIHdpZGdldCBmb3IgRXhwb3J0IHNpZ25hbHMgcG9wdXAqL1xuXG4gIC5zZWxlY3QtcGVyaW9kLWNvbXBvbmVudCB7XG4gICAgLmhlYWRlciB7XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmO1xuICAgICAgbWluLWhlaWdodDogMzhweDtcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0ICFpbXBvcnRhbnQ7XG4gICAgICAmOmhvdmVyIHtcbiAgICAgICAgYm9yZGVyLWNvbG9yOiAjYjNiM2IzICFpbXBvcnRhbnQ7XG4gICAgICB9XG4gICAgICAubGFiZWwge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBmbGV4OiAxO1xuICAgICAgICBib3JkZXItcmlnaHQ6IDEuNXB4IHNvbGlkICNjYWNhY2E7XG4gICAgICAgIGhlaWdodDogMjFweDtcbiAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgIH1cbiAgICAgID4gZGl2Omxhc3QtY2hpbGQge1xuICAgICAgICBtYXJnaW4tbGVmdDogOHB4ICFpbXBvcnRhbnQ7XG4gICAgICAgIG1hcmdpbi1yaWdodDogMDtcbiAgICAgICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAgICAgICBjb2xvcjogI2NjY2NjYyAhaW1wb3J0YW50O1xuICAgICAgICBzdmcge1xuICAgICAgICAgIHRyYW5zZm9ybTogc2NhbGUoMS40KTtcbiAgICAgICAgfVxuICAgICAgICA6aG92ZXIge1xuICAgICAgICAgIGNvbG9yOiAjOTk5OTk5O1xuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */