/* eslint-disable default-param-last */
import { useEffect, useRef } from 'react';

import { all, call, delay, takeEvery, takeLatest, select, put, debounce } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { useDispatch } from 'react-redux';

import { doRequestTags, doCreateTag } from './tags';
import {
  getFiles as getFilesApi,
  getInstructionals as getInstructionalsApi,
  deleteFile as deleteFileApi,
  uploadFile as uploadFileApi,
  updateFile as updateFileApi,
  getZippedFiles as getZippedFilesApi,
} from '../services';
import { displayNotification, checkOnline, getTempId } from './notifications';
import getNotification from './notification-defaults';
import {
  REFRESH_VALUES,
  CLEAR_SITE_DATA,
  setPollingActive,
  setPollingActiveDone,
} from './application';
import { getUniqueId } from '../utils';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const DESTROY_FILES_SESSION = 'dt/files/DESTROY_FILES_SESSION';
const REFRESH_SESSIONS = 'dt/files/REFRESH_SESSIONS';
const REQUEST_FILES = 'dt/files/REQUEST_FILES';
const REQUEST_INSTRUCTIONALS = 'dt/files/REQUEST_INSTRUCTIONALS';
const RECEIVE_FILES = 'dt/files/RECEIVE_FILES';
const RECEIVE_INSTRUCTIONALS = 'dt/files/RECEIVE_INSTRUCTIONALS';
const DELETE_FILE = 'dt/files/DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'dt/files/DELETE_FILE_SUCCESS';
const UPDATE_FILES = 'dt/files/UPDATE_FILES';
const UPLOAD_FILES = 'dt/files/UPLOAD_FILES';
const UPLOAD_FILE_STARTED = 'dt/files/UPLOAD_FILE_STARTED';
const UPLOAD_FILE_COMPLETED = 'dt/files/UPLOAD_FILE_COMPLETED';
const CLEAR_UPLOADED = 'dt/files/CLEAR_UPLOADED';
const ZIP_FILES = 'dt/files/ZIP_FILES';

const CREATE_BOOKMARK_SUCCESS = 'dt/files/CREATE_BOOKMARK_SUCCESS';
const UPDATE_BOOKMARK_SUCCESS = 'dt/files/UPDATE_BOOKMARK_SUCCESS';
const DELETE_BOOKMARK_SUCCESS = 'dt/files/DELETE_BOOKMARK_SUCCESS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const destroyFilesSession = (sessionId) => ({
  type: DESTROY_FILES_SESSION,
  sessionId,
});

// Refresh all active sessions by re-fetching files using last used query settings.
export const refreshSessions = () => ({
  type: REFRESH_SESSIONS,
});

export const requestFiles = (sessionId, query) => ({
  type: REQUEST_FILES,
  sessionId,
  query,
});

export const requestInstructionals = () => ({
  type: REQUEST_INSTRUCTIONALS,
});

export const receiveFiles = (sessionId, files, pages, query) => ({
  type: RECEIVE_FILES,
  sessionId,
  files,
  pages,
  query,
});

export const receiveInstructionals = (files) => ({
  type: RECEIVE_INSTRUCTIONALS,
  files,
});

export const uploadFileStarted = (file) => ({
  type: UPLOAD_FILE_STARTED,
  file,
});

export const uploadFileCompleted = (file, status) => ({
  type: UPLOAD_FILE_COMPLETED,
  file,
  status,
});

export const clearUploaded = () => ({
  type: CLEAR_UPLOADED,
});

export const zipFiles = (files) => ({
  type: ZIP_FILES,
  files,
});

export const uploadFiles = (siteId, files, org) => ({
  type: UPLOAD_FILES,
  siteId,
  files,
  org,
});

export const updateFiles = (siteId, files, org) => ({
  type: UPDATE_FILES,
  siteId,
  files,
  org,
});

export const deleteFile = (fileId) => ({
  type: DELETE_FILE,
  fileId,
});

export const deleteFileSuccess = (fileId) => ({
  type: DELETE_FILE_SUCCESS,
  fileId,
});

export const createBookmarkSuccess = ({ fileId, bookmark }) => ({
  type: CREATE_BOOKMARK_SUCCESS,
  fileId,
  bookmark,
});

export const updateBookmarkSuccess = ({ fileId, bookmarkId, bookmark }) => ({
  type: UPDATE_BOOKMARK_SUCCESS,
  fileId,
  bookmarkId,
  bookmark,
});

export const deleteBookmarkSuccess = ({ fileId, bookmarkId }) => ({
  type: DELETE_BOOKMARK_SUCCESS,
  fileId,
  bookmarkId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  sessions: {},
  fileObjects: {},
  instructionals: {},
  uploading: {},
  uploaded: {},
  zipping: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DESTROY_FILES_SESSION: {
      const sessions = { ...state.sessions };
      delete sessions[action.sessionId];

      return { ...state, sessions };
    }
    case REQUEST_FILES: {
      if (state.sessions[action.sessionId]) {
        return {
          ...state,
          sessions: {
            ...state.sessions,
            [action.sessionId]: {
              ...state.sessions[action.sessionId],
              loading: true,
            },
          },
        };
      }
      return state;
    }
    case RECEIVE_FILES: {
      const { sessionId, files, pages, query } = action;
      const fileObjects = { ...state.fileObjects };

      files.forEach((file) => {
        fileObjects[file.id] = file;
      });

      return {
        ...state,
        fileObjects,
        sessions: {
          ...state.sessions,
          [sessionId]: {
            files: files.map((f) => f.id),
            pages,
            query,
            loading: false,
          },
        },
      };
    }
    case RECEIVE_INSTRUCTIONALS: {
      return {
        ...state,
        instructionals: action.files
          .filter((file) => file.context.name)
          .reduce(
            (acc, file) => ({
              ...acc,
              [file.context.name]: file,
            }),
            {}
          ),
      };
    }
    case DELETE_FILE_SUCCESS: {
      const fileObjects = { ...state.fileObjects };
      delete fileObjects[action.fileId];

      return { ...state, fileObjects };
    }
    case CREATE_BOOKMARK_SUCCESS: {
      const { fileId, bookmark } = action;
      const fileObjects = { ...state.fileObjects };

      fileObjects[fileId].bookmarks = [...fileObjects[fileId].bookmarks, bookmark];

      return { ...state, fileObjects };
    }
    case UPDATE_BOOKMARK_SUCCESS: {
      const { fileId, bookmarkId, bookmark } = action;
      const fileObjects = { ...state.fileObjects };

      fileObjects[fileId].bookmarks = [
        ...fileObjects[fileId].bookmarks.filter(({ id }) => id !== bookmarkId),
        bookmark,
      ];

      return { ...state, fileObjects };
    }
    case DELETE_BOOKMARK_SUCCESS: {
      const { fileId, bookmarkId } = action;
      const fileObjects = { ...state.fileObjects };

      if (fileObjects[fileId]) {
        fileObjects[fileId].bookmarks = fileObjects[fileId].bookmarks.filter(
          ({ id }) => id !== bookmarkId
        );
      }

      return { ...state, fileObjects };
    }
    case UPLOAD_FILE_STARTED: {
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [action.file.clientIdentifier]: action.file,
        },
      };
    }
    case UPLOAD_FILE_COMPLETED: {
      const { file, status } = action;
      const newUploading = { ...state.uploading };
      const uploadedFiles = {
        ...state.uploaded,
        [file.clientIdentifier]: { ...file, status },
      };
      const uploadedNum = Object.entries(uploadedFiles).filter(([, v]) =>
        ['SUCCESS'].some((s) => s === v.status)
      );
      delete newUploading[file.clientIdentifier];
      return {
        ...state,
        uploading: newUploading,
        uploaded: uploadedFiles,
        totalFilesUploaded: uploadedNum.length,
      };
    }
    case CLEAR_UPLOADED: {
      return { ...state, uploaded: {} };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        sessions: {},
        fileObjects: {},
        uploading: {},
        uploaded: {},
        zipping: [],
      };
    }
    default: {
      return state;
    }
  }
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

/**
 * Get a a files object representing the state of the submitted ref.
 *
 * @example
 *
 *   useSelector(state => getFiles(state, ref));
 *   // { files: [], pages: [], query: {} }
 *
 */
export const getFiles = createSelector(
  ({ files }) => files,
  (_, sessionId) => sessionId,
  ({ sessions, fileObjects }, sessionId) => {
    if (sessions[sessionId]) {
      return {
        ...sessions[sessionId],
        files: sessions[sessionId].files.reduce((acc, id) => {
          if (fileObjects[id]) acc.push(fileObjects[id]);
          return acc;
        }, []),
      };
    }

    return {
      files: null,
      pages: null,
      loading: false,
    };
  }
);

export const getUploading = ({ files }) => Object.values(files.uploading);

export const getUploaded = ({ files }) => Object.values(files.uploaded);

export const getInstructional = ({ files }, fileName) => files.instructionals[fileName] || null;

// Hooks

/**
 * Hook into the component lifecycle to create a session reference that is
 * automatically returned when the component unmounts.
 *
 * @example
 *
 *   const sessionId = useFilesSession();
 *   dispatch(requestFiles(sessionId, ...));
 *
 */
export const useFilesSession = () => {
  const { current: sessionId } = useRef(getUniqueId());
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(destroyFilesSession(sessionId)), []);

  return sessionId;
};

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

function getNewTags(localTags, files) {
  if (!localTags.length) return [];
  return files.reduce((acc, { tags }) => {
    if (tags.length) {
      tags.forEach((tag) => {
        if (localTags.map((t) => t.name).includes(tag) && !acc.includes(tag)) {
          acc.push(tag);
        }
      });
    }
    return acc;
  }, []);
}

function getTagIds(allTags, tags) {
  return tags
    .map((tag) => (allTags.find((t) => t.name === tag || t.id === tag) || {}).id)
    .filter((tag) => !!tag);
}

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doDeleteFile(action) {
  const { fileId } = action;
  const fileObject = yield select((state) => state.files.fileObjects[fileId]);

  if (!fileObject) return;

  try {
    yield call(deleteFileApi, fileId);
    yield put(deleteFileSuccess(fileId));
    yield put(displayNotification(getNotification('deleteFile', 'success')(fileObject.filename)));
    // TODO: Maybe remove call to refresh and hook into DELETE_FILE_SUCCESS to
    // remove the file in all active sessions? Downside is that pagination
    // will not be accurate.
    yield put(refreshSessions());
  } catch (e) {
    console.error('Unable to delete file: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(getNotification('deleteFile', 'error')(fileObject.filename, fileId))
    );
  }
}

function* doRequestFiles({ sessionId, query: originalQuery }) {
  const query = {
    ...originalQuery,
    page: originalQuery.page || 1,
    sortBy: originalQuery.sortBy || 'createdAt',
    order: originalQuery.order || 'desc',
  };
  if (!originalQuery.search) {
    delete query.search;
  }
  if (!originalQuery.fileType) {
    delete query.fileType;
  }

  try {
    const { values: receivedFiles, pages } = yield call(getFilesApi, query);
    yield put(receiveFiles(sessionId, receivedFiles, pages, query));
  } catch (e) {
    console.error('Unable to fetch files: ', e);
    yield call(checkOnline);
    yield put(receiveFiles(sessionId, [], null, query));
    yield put(displayNotification(getNotification('getFiles', 'error')()));
  }
}

function* doRequestInstructionals() {
  try {
    const { values: receivedFiles } = yield call(getInstructionalsApi);
    yield put(receiveInstructionals(receivedFiles));
  } catch (e) {
    console.error('Unable to fetch files: ', e);
    yield call(checkOnline);
    yield put(receiveInstructionals([]));
    yield put(displayNotification(getNotification('getFiles', 'error')()));
  }
}

function* doRefreshSessions() {
  yield put(setPollingActive('files'));

  const sessions = yield select((state) => state.files.sessions);

  yield all(
    Object.entries(sessions).map(([sessionId, { query }]) =>
      call(doRequestFiles, { sessionId, query })
    )
  );
  yield put(setPollingActiveDone('files'));
}

function* doUploadFile(file, totalFiles) {
  const formData = new FormData();
  Object.keys(file).forEach((key) => {
    if (Array.isArray(file[key])) {
      file[key].forEach((item) => {
        formData.append(`${key}[]`, item);
      });
    } else {
      formData.append(key, file[key]);
    }
  });

  yield put(uploadFileStarted(file));
  const tempId = getTempId();
  if (totalFiles === 1)
    yield put(
      displayNotification(
        getNotification('createFile', 'pre')(`Started uploading ${file.file.name}`, tempId)
      )
    );

  try {
    yield call(uploadFileApi, formData);

    yield put(uploadFileCompleted(file, 'SUCCESS'));
    if (totalFiles === 1)
      yield put(
        displayNotification(getNotification('createFile', 'success')(file.file.name, tempId))
      );

    yield put(refreshSessions());
    yield delay(3000);
  } catch (e) {
    console.error('Unable to upload file: ', e);
    yield call(checkOnline);
    yield put(uploadFileCompleted(file, 'ERROR'));
    yield put(displayNotification(getNotification('createFile', 'error')(file.file.name, tempId)));
  }
}

function* doUploadFiles(action) {
  const { siteId, files, org } = action;
  const uploading = yield select(getUploading);
  const uploaded = yield select(getUploaded);
  if ((uploading && uploading.length === 0) || uploaded) {
    yield put(clearUploaded());
  }

  try {
    const localTags = yield select((state) =>
      state.tags.tags.filter((t) => t.site === siteId && t.name === t.id)
    );
    const newUniqueTags = getNewTags(localTags, files);

    if (newUniqueTags.length) {
      yield all([
        ...newUniqueTags.map((tagName) =>
          call(doCreateTag, {
            org,
            site: siteId,
            name: tagName,
          })
        ),
      ]);
      yield call(doRequestTags, { query: { site: siteId } });
    }

    const updatedTags = yield select((state) => state.tags.tags.filter((t) => t.site === siteId));
    const tempId = getTempId();
    if (files.length > 1)
      yield put(
        displayNotification(
          getNotification('createFile', 'pre')(
            `Started uploading ${files.length} documents`,
            tempId
          )
        )
      );
    yield all([
      ...files.map((file) =>
        call(doUploadFile, { ...file, tags: getTagIds(updatedTags, file.tags) }, files.length)
      ),
    ]);
    const totalFilesUploaded = yield select((state) => state.files.totalFilesUploaded);
    if (totalFilesUploaded > 1)
      yield put(
        displayNotification(getNotification('createFile', 'size')(totalFilesUploaded, files.length))
      );
  } catch (e) {
    // individual tag creation / file upload failures are handled, this should catch
    // and display upload failure based on tag failure.
    console.error('Unable to upload files: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('filesCreateTag', 'error')('upload')));
  }
}

function* doUpdateFile(file) {
  yield put(displayNotification(getNotification('updateFile', 'pre')(file.file.name, file.id)));

  try {
    yield call(updateFileApi, file.id, file);
    yield put(
      displayNotification(getNotification('updateFile', 'success')(file.file.name, file.id))
    );
    // TODO: This is in theory not necessary, we could just update the updated
    // file in all active sessions.
    yield put(refreshSessions());
  } catch (e) {
    console.error('Unable to update file: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateFile', 'error')(file.file.name, file.id)));
  }
}

function* doUpdateFiles(action) {
  const { siteId, files, org } = action;

  try {
    const localTags = yield select((state) =>
      state.tags.tags.filter((t) => t.site === siteId && t.name === t.id)
    );
    const newUniqueTags = getNewTags(localTags, files);
    if (newUniqueTags.length) {
      yield all([
        ...newUniqueTags.map((tagName) =>
          call(doCreateTag, {
            org,
            site: siteId,
            name: tagName,
          })
        ),
      ]);
      yield call(doRequestTags, { query: { site: siteId } });
    }

    const updatedTags = yield select((state) => state.tags.tags.filter((t) => t.site === siteId));
    yield all([
      ...files.map((file) =>
        call(doUpdateFile, { ...file, tags: getTagIds(updatedTags, file.tags) })
      ),
    ]);
  } catch (e) {
    // individual tag creation / file upload failures are handled, this should catch
    // and display upload failure based on tag failure.
    console.error('Unable to update files: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('filesCreateTag', 'error')('update')));
  }
}

function* doZipFiles(action) {
  const { files } = action;
  const [{ site, org }] = files;

  try {
    const { resourceUrl, notFound } = yield call(getZippedFilesApi, {
      file: files.map((f) => f.id),
      site,
      org,
    });
    if (files.length - notFound.length === 1) {
      // We revert to the individual file's url since getZippedFiles returns one that
      // points to an endpoint which expects an array, bu twe only have 1
      const availableFile = files.find(({ id }) => !notFound.includes(id));
      window.location = `${window.location.protocol}//${availableFile.resourceUrl}`;
    } else {
      window.location = `${window.location.protocol}//${resourceUrl}`;
    }
    if (notFound.length > 0) {
      yield put(
        displayNotification(
          getNotification('getZippedFiles', 'partialSuccess')(
            notFound.length,
            notFound
              .map((nfId) => {
                const file = files.find(({ id }) => id === nfId);
                return file && file.filename;
              })
              .join(',')
          )
        )
      );
    }
  } catch (e) {
    console.error('Unable to download files: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getZippedFiles', 'error')()));
  }
}

export const sagas = [
  takeEvery(REQUEST_FILES, doRequestFiles),
  takeEvery(REQUEST_INSTRUCTIONALS, doRequestInstructionals),
  takeEvery(DELETE_FILE, doDeleteFile),
  takeLatest(UPLOAD_FILES, doUploadFiles),
  takeLatest(UPDATE_FILES, doUpdateFiles),
  takeEvery(ZIP_FILES, doZipFiles),
  takeLatest(REFRESH_SESSIONS, doRefreshSessions),

  debounce(500, REFRESH_VALUES, doRefreshSessions),
];
