.data-container .column {
  position: relative;
  padding: 20px 10px 10px 10px;
  margin-right: 10px;
}
.data-container .title {
  position: absolute;
  top: -14px;
  left: 5px;
  padding: 5px;
  background: #fff;
}

.dark-theme .data-container .title {
  color: #fff;
  background: #262626;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXdPdmVydmlldyIsInNvdXJjZXMiOlsiQmFzZVZpZXdPdmVydmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0k7RUFDQTtFQUNBOztBQUdKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBS1I7RUFDSTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmRhdGEtY29udGFpbmVyIHtcblxuICAgIC5jb2x1bW4ge1xuICAgICAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgICAgIHBhZGRpbmc6IDIwcHggMTBweCAxMHB4IDEwcHg7XG4gICAgICAgIG1hcmdpbi1yaWdodDogMTBweDtcbiAgICB9XG5cbiAgICAudGl0bGUge1xuICAgICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgICAgIHRvcDogLTE0cHg7XG4gICAgICAgIGxlZnQ6IDVweDtcbiAgICAgICAgcGFkZGluZzogNXB4O1xuICAgICAgICBiYWNrZ3JvdW5kOiAjZmZmO1xuICAgIH1cblxufVxuXG4uZGFyay10aGVtZSAuZGF0YS1jb250YWluZXIgLnRpdGxlIHtcbiAgICBjb2xvcjogI2ZmZjtcbiAgICBiYWNrZ3JvdW5kOiAjMjYyNjI2O1xufSJdfQ== */