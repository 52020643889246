const defaultMap = {
  name: 'name',
  org: 'org',
  customer: 'customer',
  city: 'city',
  country: 'country',
  lat: 'lat',
  lng: 'lng',
  thirdPartyId: 'thirdPartyId',
};

// eslint-disable-next-line import/prefer-default-export
export function mapUrlParams(urlParamMap, urlParams) {
  const mapping = { ...defaultMap, ...urlParamMap };
  return Object.keys(mapping).reduce((acc, param) => {
    acc[param] = urlParams.get(mapping[param]);
    return acc;
  }, {});
}
