import { Button } from '@avtjs/react-components';
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import JSONEditor from '../../../../../JSONEditor';
import SimpleModal from '../../../../../SimpleModal/SimpleModal';
import { getPermissionsSchema, getPermissionsUiSchema, getRoleSchema } from '../schema';
import { createMemberAndConnection, updateMember } from '../../../../../../bundles/auth';

const CustomRolesModal = ({ onCloseModal, members, allPermissions, scope, objectId, member }) => {
  const initialFormData = {
    name: member ? member.type : '',
    selectedRole: member ? member.type : '',
  };

  const [roleFormData, setRoleFormData] = useState(initialFormData);
  const [permissionFormData, setPermissionFormData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const memberPermissions = members.find((m) => m.type === roleFormData.selectedRole);
    const initialMemberPermissions = allPermissions.reduce(
      (acc, permission) => ({
        ...acc,
        [permission]: [false, false, false],
      }),
      {}
    );

    const formattedMemberPermissions = memberPermissions?.permissions.reduce(
      (acc, entityPermission) => {
        const permissionMap = {
          Read: 0,
          Write: 1,
          Delete: 2,
        };

        const entity = entityPermission.split('/')[0];
        const permisson = entityPermission.split('/')[1];
        const currentPermissions = acc[entity] || [false, false, false];

        return {
          ...acc,
          [entity]: currentPermissions.with(permissionMap[permisson], true),
        };
      },
      initialMemberPermissions
    );

    setPermissionFormData(formattedMemberPermissions);
  }, [roleFormData.selectedRole]);

  const [step, setStep] = useState(0);
  const formRef = useRef();

  const roleSchema = getRoleSchema(members, member);
  const permissionSchema = getPermissionsSchema(allPermissions);
  const permissionUiSchema = getPermissionsUiSchema(allPermissions);

  const onRoleFormChange = ({ formData: changes }) => {
    setRoleFormData(changes);
  };

  const onPermissionFormChange = ({ formData: changes }) => {
    setPermissionFormData(changes);
  };

  const handleOnClose = () => {
    setRoleFormData(initialFormData);
    setPermissionFormData({});
    onCloseModal();
  };

  const onSelectedRole = useCallback(
    (e) => {
      const { errors } = formRef.current.validate(roleFormData);
      if (errors.length) {
        formRef.current.onSubmit(e);
      } else {
        setStep(1);
      }
    },
    [formRef.current, roleFormData]
  );

  const onBack = () => {
    setStep(0);
  };

  const customValidate = useCallback(
    (formData, errors) => {
      const reservedNames = members.filter((m) => m.id !== member?.id).map((m) => m.type);

      if (reservedNames.includes(formData.name)) {
        errors.name.addError(`${formData.name} is a reserved name`);
      }
      if (!formData.name) {
        errors.name.addError('is requred');
      }
      return errors;
    },
    [members, member]
  );

  const transformErrors = (errors) =>
    errors.map((error) => {
      const customError = { ...error };
      if (customError.property === '.selectedRole') {
        customError.message = 'is required';
      }
      return customError;
    });

  const onPermissionSubmit = () => {
    const permissions = Object.entries(permissionFormData).reduce((acc, [key, value]) => {
      value.forEach((val, i) => {
        const permissionMap = {
          0: 'Read',
          1: 'Write',
          2: 'Delete',
        };

        if (val) {
          acc.push(`${key}/${permissionMap[i]}`);
        }
      });
      return acc;
    }, []);

    if (member) {
      dispatch(
        updateMember({
          type: roleFormData.name,
          custom: true,
          scope,
          permissions,
          objectId,
          id: member.id,
        })
      );
    } else {
      dispatch(
        createMemberAndConnection({
          type: roleFormData.name,
          custom: true,
          scope,
          permissions,
          objectId,
        })
      );
    }

    handleOnClose();
  };

  const roleForm = useMemo(() => {
    return (
      <React.Fragment>
        <JSONEditor
          formData={roleFormData}
          schema={roleSchema}
          formRef={formRef}
          showButtons={false}
          showNonEditForm={false}
          showEditButton={false}
          onFormChange={onRoleFormChange}
          editorOnly
          customTransformErrors={(errors) => transformErrors(errors)}
          customValidate={customValidate}
        />
      </React.Fragment>
    );
  }, [roleFormData, step]);

  const permissionForm = useMemo(() => {
    return (
      <React.Fragment>
        <div className="permissions-header">
          <div></div>
          <h4>Read</h4>
          <h4>Write</h4>
          <h4>Delete</h4>
        </div>
        <JSONEditor
          formData={permissionFormData}
          schema={permissionSchema}
          showButtons={false}
          uiSchema={permissionUiSchema}
          initialEditMode
          editorOnly
          showEditButton={false}
          onFormChange={onPermissionFormChange}
          onFormSubmit={onPermissionSubmit}
          className={'custom-role-form'}
        />
      </React.Fragment>
    );
  }, [permissionFormData, step, formRef.current]);

  return (
    <SimpleModal
      onClose={handleOnClose}
      overlayCanClose={false}
      className="custom-role-modal"
      title={member ? 'Edit role' : 'Create new role'}
    >
      <div>{step === 0 ? roleForm : permissionForm}</div>
      <div className="custom-role-modal-button-container">
        <Button
          activity="secondary"
          onClick={step === 0 ? handleOnClose : onBack}
        >
          {step === 0 ? 'Cancel' : 'Back'}
        </Button>

        <Button onClick={step === 0 ? (e) => onSelectedRole(e) : onPermissionSubmit}>
          {step === 0 ? 'Next' : 'Save'}
        </Button>
      </div>
    </SimpleModal>
  );
};

export default CustomRolesModal;
