import React, { memo } from 'react'; // eslint-disable-line no-unused-vars

import JSONEditor from '../JSONEditor';
import SimpleModal from '../SimpleModal';

const EditItemModal = memo(
  ({
    entity,
    action = 'Add',
    formData = {},
    schema,
    uiSchema,
    onSubmit,
    onChange = () => null,
    onCloseModal,
    withTitle = true,
  }) => (
    <SimpleModal
      onClose={onCloseModal}
      className="create-item-modal"
      title={`${action} ${entity}`}
    >
      <div className="create-item-content">
        <JSONEditor
          title={withTitle ? entity : ''}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onFormSubmit={onSubmit}
          onFormChange={onChange}
          saveButtonText={action}
          initialEditMode={true}
          showEditButton={false}
          cancelCallback={onCloseModal}
        />
      </div>
    </SimpleModal>
  )
);

export default EditItemModal;
