/* eslint-disable default-param-last */
import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import {} from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_TAGS = 'dt/tags/REQUEST_TAGS';
export const CREATE_TAG = 'dt/tags/CREATE_TAG';
export const UPDATE_TAG = 'dt/tags/UPDATE_TAG';
export const DELETE_TAGS = 'dt/tags/DELETE_TAGS';
export const RECEIVE_TAGS = 'dt/tags/RECEIVE_TAGS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestTags = (siteId) => ({
  type: REQUEST_TAGS,
  query: { site: siteId },
});

export const createTag = (tag) => ({
  type: CREATE_TAG,
  tag,
});

export const receiveTags = (tags) => ({
  type: RECEIVE_TAGS,
  tags,
});

export const updateTag = (tag) => ({
  type: UPDATE_TAG,
  tag,
});

export const deleteTags = (siteId, tagIds) => ({
  type: DELETE_TAGS,
  siteId,
  tagIds,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  tags: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_TAGS: {
      const { tags } = action;
      return { ...state, tags };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return { ...state, tags: [] };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getTags = (state) => state.tags.tags;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doRequestTags({ query }) {
  try {
    const { values: tags } = yield call(getTagsApi, query);
    tags.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    const oldTags = yield select(getTags);
    if (JSON.stringify(oldTags) !== JSON.stringify(tags)) {
      yield put(receiveTags(tags));
    }
  } catch (e) {
    yield console.error('Unable to fetch tags: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getTags', 'error')()));
  }
}

export function* doCreateTag({ tag }) {
  try {
    yield createTagApi(tag);
  } catch (e) {
    console.error('Unable to create tag: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createTag', 'error')()));
  }
}

export function* doUpdateTag({ tag }) {
  const { id, ...tagUpdate } = tag;
  try {
    yield updateTagApi(id, tagUpdate);
    yield call(doRequestTags, { query: { site: tag.site } });
    yield put(displayNotification(getNotification('updateTag', 'success')(id)));
  } catch (e) {
    console.error('Unable to update tag: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateTag', 'error')(id)));
  }
}

function* doDeleteTag(tagId) {
  try {
    yield deleteTagApi(tagId);
    yield put(displayNotification(getNotification('deleteTag', 'success')(tagId)));
  } catch (e) {
    console.error('Unable to delete tag: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteTag', 'error')(tagId)));
  }
}

export function* doDeleteTags({ siteId, tagIds }) {
  yield all([...tagIds.map((tagId) => call(doDeleteTag, tagId))]);
  yield call(doRequestTags, { query: { site: siteId } });
}

export const sagas = [
  takeLatest(REQUEST_TAGS, doRequestTags),
  takeLatest(CREATE_TAG, doCreateTag),
  takeLatest(UPDATE_TAG, doUpdateTag),
  takeLatest(DELETE_TAGS, doDeleteTags),
];
