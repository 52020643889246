.foldable-tree-view .tree {
  list-style: none;
  margin: 0;
  padding: 0;
}
.foldable-tree-view .tree-node,
.foldable-tree-view .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}
.foldable-tree-view .tree-branch-wrapper {
  outline: none;
}
.foldable-tree-view .tree-node--focused {
  outline-color: rgb(77, 144, 254);
  outline-style: auto;
  outline-width: 2px;
  display: block;
}
.foldable-tree-view .tree-node__branch {
  display: block;
}
.foldable-tree-view .tree-node {
  cursor: pointer;
  outline: none;
  padding: 2px 0;
}
.foldable-tree-view .tree-leaf-list-item .tree-node {
  cursor: default;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXdPdmVydmlldyIsInNvdXJjZXMiOlsiVHJlZVZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNJO0VBQ0E7RUFDQTs7QUFHSjtBQUFBO0VBRUk7RUFDQTtFQUNBOztBQUdKO0VBQ0k7O0FBR0o7RUFDSTtFQUNBO0VBQ0E7RUFDQTs7QUFHSjtFQUNJOztBQUdKO0VBQ0k7RUFDQTtFQUNBOztBQUdKO0VBQ0kiLCJzb3VyY2VzQ29udGVudCI6WyIuZm9sZGFibGUtdHJlZS12aWV3IHtcblxuICAgIC50cmVlIHtcbiAgICAgICAgbGlzdC1zdHlsZTogbm9uZTtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgICAgICBwYWRkaW5nOiAwO1xuICAgIH1cblxuICAgIC50cmVlLW5vZGUsXG4gICAgLnRyZWUtbm9kZS1ncm91cCB7XG4gICAgICAgIGxpc3Qtc3R5bGU6IG5vbmU7XG4gICAgICAgIG1hcmdpbjogMDtcbiAgICAgICAgcGFkZGluZzogMDtcbiAgICB9XG5cbiAgICAudHJlZS1icmFuY2gtd3JhcHBlciB7XG4gICAgICAgIG91dGxpbmU6IG5vbmU7XG4gICAgfVxuXG4gICAgLnRyZWUtbm9kZS0tZm9jdXNlZCB7XG4gICAgICAgIG91dGxpbmUtY29sb3I6IHJnYig3NywgMTQ0LCAyNTQpO1xuICAgICAgICBvdXRsaW5lLXN0eWxlOiBhdXRvO1xuICAgICAgICBvdXRsaW5lLXdpZHRoOiAycHg7XG4gICAgICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIH1cblxuICAgIC50cmVlLW5vZGVfX2JyYW5jaCB7XG4gICAgICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIH1cblxuICAgIC50cmVlLW5vZGUge1xuICAgICAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAgICAgIG91dGxpbmU6IG5vbmU7XG4gICAgICAgIHBhZGRpbmc6IDJweCAwO1xuICAgIH1cblxuICAgIC50cmVlLWxlYWYtbGlzdC1pdGVtIC50cmVlLW5vZGUge1xuICAgICAgICBjdXJzb3I6IGRlZmF1bHQ7XG4gICAgfVxuXG59Il19 */