import React from 'react';
import SiteCreateView from '../../SiteCreateView';
import { mapUrlParams } from './util';

const urlParamMap = {
  thirdPartyId: 'rootAssetId',
  name: 'rootAssetName',
  org: 'rootAssetOrg',
  customer: 'rootAssetCustomer',
  city: 'rootAssetCity',
  country: 'rootAssetCountry',
  lat: 'rootAssetLocationX',
  lng: 'rootAssetLocationY',
};

export default ({ urlParams, realm }) => {
  const siteParams = mapUrlParams(urlParamMap, urlParams);

  siteParams.uiMode = 'Lumada';
  // TODO: Refactor away this, and instead use the properties to avoid duplication
  siteParams.integrationInfo = {
    isIntegration: true,
    name: 'Lumada',
  };

  siteParams.properties = [
    {
      attribute: 'integration',
      name: 'integration',
      value: {
        thirdPartyId: siteParams.thirdPartyId,
        integrationType: 'lumada',
      },
      category: 'Integrations',
    },
  ];

  siteParams.defaultLayout = {
    pages: [
      {
        path: 'path- 48806',
        title: 'Untitled page',
        sections: [
          {
            title: 'Untitled section',
            columns: [
              {
                title: 'Untitled column',
                panels: [
                  {
                    type: 'component-source-properties',
                    title: 'Asset',
                    componentScope: {
                      includeAllComponents: true,
                      includeActiveComponent: false,
                      includeComponentChildren: false,
                      activeComponentTypeFilter: 'any',
                    },
                    options: {
                      allowFloat: true,
                    },
                    allowFullScreen: false,
                  },
                ],
              },
              {
                title: 'Untitled column',
                panels: [
                  {
                    type: 'components',
                    title: 'components',
                    componentScope: {
                      includeAllComponents: true,
                      includeActiveComponent: false,
                      includeComponentChildren: false,
                      activeComponentTypeFilter: 'any',
                    },
                    options: {
                      allowFloat: true,
                    },
                    allowFullScreen: false,
                  },
                ],
              },
            ],
            displayWhen: {},
          },
        ],
      },
    ],
    type: 'pt',
  };

  return (
    <SiteCreateView
      siteParams={siteParams}
      realm={realm}
    />
  );
};
