import { camelToSentence } from '../../../../utils';

const componentIconList = [
  'avt-dot',
  'avt-reactor',
  'abb-04-variables',
  'abb-battery-empty',
  'abb-battery-half',
  'abb-battery-full',
  'abb-binary-data',
  'abb-close',
  'abb-controller-cabinet',
  'abb-double-right',
  'abb-drive',
  'abb-fieldbus',
  'abb-folder',
  'abb-group',
  'abb-home',
  'abb-io-devices',
  'abb-key',
  'abb-joystick',
  'abb-level',
  'abb-magnet',
  'abb-network',
  'abb-panel',
  'abb-plugin',
  'abb-pump',
  'abb-robot-tool',
  'abb-robot',
  'abb-sld-1',
  'abb-sld-2',
  'abb-solar-building',
  'abb-stop',
  'abb-system',
  'abb-temperature-celsius-2',
  'abb-temperature-fahrenheit-2',
  'abb-temperature',
  'abb-track-2',
  'abb-transformer',
  'abb-tree-view',
  'abb-video-camera-on',
];

const sortByKey = (items, sortKey1, sortKey2 = '') =>
  items.sort((a, b) => {
    const { [sortKey1]: a1, [sortKey2]: a2 } = a;
    const { [sortKey1]: b1, [sortKey2]: b2 } = b;
    return (a1 + a2).localeCompare(b1 + b2);
  });

const getComponentSchema = (
  component = {},
  components = [],
  models = [],
  sources = [],
  sourceTypes = [],
  stateSets = [],
  variables = []
) => {
  const filteredSources = sources.filter((s) => sourceTypes.map((st) => st.name).includes(s.type));

  const modelSchema = models.length
    ? {
        models: {
          type: 'array',
          title: 'Models',
          items: {
            type: 'object',
            required: ['modelId', 'customName'],
            properties: {
              modelId: {
                type: 'string',
                title: 'Model',
                placeholder: 'Select a model',
                enum: models.map((m) => m.id),
                enumNames: models.map((m) => `${m.name} ${m.id}`),
              },
              customName: {
                type: 'string',
                title: 'Display name',
              },
            },
          },
        },
      }
    : {};

  const commonNonVirtualProps = {
    icon: {
      type: 'string',
      title: 'Icon',
      icons: componentIconList,
      showLabel: false,
    },

    ...modelSchema,

    source: {
      type: ['string', 'null'],
      title: 'Data Source',
      default: null,
      placeholder: 'None',
      enum: [null, ...filteredSources.map((s) => s.id)],
      enumNames: ['None', ...filteredSources.map((s) => s.name)],
    },

    ...(filteredSources.length
      ? {
          eventSources: {
            type: 'array',
            title: 'Event Sources',
            items: { $ref: '#/definitions/eventSourceItems' },
          },
        }
      : {}),

    variables: {
      isMulti: true,
      type: 'array',
      title: 'Status Signals',
      uniqueItems: true,
      default: [],
      items: {
        type: ['string', 'null'],
        enum: [null, ...variables.map((s) => s.id)],
        enumNames: ['None', ...variables.map((s) => s.name)],
      },
    },
  };

  const sortedCompsNotSelfOrDescendant = components
    .sort((a, b) => a.itemDesignation.toLowerCase().localeCompare(b.itemDesignation.toLowerCase()))
    .filter((c) => c.id !== component.id && !component.descendantIds?.includes(c.id));

  const commonNonSiteProps = {
    parent: {
      type: 'string',
      title: 'Parent',
      enum: sortedCompsNotSelfOrDescendant.map((c) => c.id),
      enumNames: sortedCompsNotSelfOrDescendant.map((c) => `${c.name} (${c.itemDesignation})`),
    },
  };

  const sourceSchemaConfigProperties = filteredSources.map((source) => {
    const sourceType = sourceTypes.find((st) => st.name === source.type);
    const sourceSchemaProperties = {
      source: { enum: [source.id] },
    };

    if (sourceType && Object.keys(sourceType.schemas.component.properties).length) {
      sourceSchemaProperties.sourceOptions = {
        type: 'object',
        title: '',
        required: sourceType.schemas.component.required,
        properties: {
          ...Object.entries(sourceType.schemas.component.properties).reduce((acc, [k, v]) => {
            acc[k] = { ...v, title: camelToSentence(k) };
            return acc;
          }, {}),
        },
      };
    }

    if (sourceType && Object.keys(sourceType.schemas.config.properties).length) {
      let sortedDevices = [];

      if (source.options.devices && source.options.devices.length) {
        sortedDevices = sortByKey(
          source.options.devices,
          source.options.devices[0].itemDesignation ? 'itemDesignation' : 'name'
        );
      }

      sourceSchemaProperties.sourceConfig = {
        title: `${source.name} specific implementation details`,
        type: 'object',
        properties: {
          ...Object.entries(sourceType.schemas.config.properties).reduce((acc, [k, v]) => {
            if (k === 'objectId') {
              if (!sortedDevices.length) {
                sortedDevices.push({
                  objectId: null,
                  error: `No devices available. Have you imported ${source.name} assets?`,
                });
              }
              acc.objectId = {
                title: `${source.name} device mapping`,
                description: `Choose which ${source.name} device this component should represent.`,
                type: ['string', 'null'],
                enum: sortedDevices.map((device) => device.objectId),
                enumNames: sortedDevices.map(
                  (device) => device.error || `[${device.itemDesignation}]  ${device.name}`
                ),
              };
            } else {
              acc[k] = { ...v, title: camelToSentence(k) };
            }
            return acc;
          }, {}),
        },
      };
    }
    return { properties: sourceSchemaProperties };
  });

  const schema = {
    type: 'object',
    required: ['name', 'type', 'itemDesignation'],

    definitions: {
      eventSourceItems: {
        title: 'Event Source',
        type: 'object',

        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          description: {
            type: 'string',
            title: 'Description',
          },
          source_id: {
            type: ['string', 'null'],
            title: 'Data Source',
            default: null,
            enum: [null, ...filteredSources.map((source) => source.id)],
            enumNames: ['None', ...filteredSources.map((source) => source.name)],
          },
        },

        dependencies: {
          source_id: {
            oneOf: [
              {
                properties: {
                  source_id: { enum: [null] },
                },
              },
              ...filteredSources.map((source) => {
                const sourceType = sourceTypes.find((st) => st.name === source.type);

                return {
                  properties: {
                    source_id: { enum: [source.id] },

                    options: {
                      type: 'object',
                      title: '',
                      required: sourceType.schemas.event.required,
                      properties: {
                        ...Object.entries(sourceType.schemas.event.properties).reduce(
                          (acc, [k, v]) => {
                            acc[k] = { ...v, title: camelToSentence(k) };
                            return acc;
                          },
                          {}
                        ),
                      },
                    },
                  },
                };
              }),
            ],
          },
        },
      },
    },

    properties: {
      name: {
        type: 'string',
        title: 'Name',
        default: '',
      },

      itemDesignation: {
        type: 'string',
        title: 'Item Designation',
      },
    },

    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: { enum: ['virtual'] },

              ...commonNonSiteProps,

              stateset_id: {
                type: ['string', 'null'],
                title: 'Area status',
                default: null,
                enum: [null, ...stateSets.filter((s) => !!s.isAreaStateSet).map((s) => s.id)],
                enumNames: [
                  'None',
                  ...stateSets.filter((s) => !!s.isAreaStateSet).map((s) => s.name),
                ],
                placeholder: 'Select a state',
              },
            },
          },
          {
            properties: {
              type: { enum: ['branch', 'group', 'component'] },
              ...commonNonSiteProps,
              ...commonNonVirtualProps,
            },
            dependencies: {
              source: {
                oneOf: [
                  {
                    properties: {
                      source: { enum: [null, undefined, ''] },
                    },
                  },
                  ...sourceSchemaConfigProperties,
                ],
              },
            },
          },
          {
            properties: {
              type: { enum: ['site'] },
              ...commonNonVirtualProps,
            },
            dependencies: {
              source: {
                oneOf: [
                  {
                    properties: {
                      source: { enum: [null, undefined, ''] },
                    },
                  },
                  ...sourceSchemaConfigProperties,
                ],
              },
            },
          },
        ],
      },
    },
  };

  if (component.type !== 'site') {
    schema.properties.type = {
      type: 'string',
      title: 'Type',
      enum: ['branch', 'group', 'component', 'virtual'],
      enumNames: ['branch', 'group', 'component', 'area'],
      default: 'component',
    };
    schema.required.push('parent');
  } else {
    schema.properties = {
      type: {
        type: 'string',
        title: 'Type',
        enum: ['site'],
        enumNames: ['site'],
        default: 'site',
      },
      ...schema.properties,
      ...commonNonVirtualProps,
    };
  }
  return schema;
};

const VALID_ORDERED_COMPONENT_KEYS = [
  'name',
  'itemDesignation',
  'parent',
  'type',
  'icon',
  'source', // component.source_id re-keyed (duplicate key error with event source keys)
  'sourceOptions', // component.options re-keyed
  'sourceConfig',
  'models',
  'eventSources',
  'stateset_id',
  'variables',
];

const componentUiSchema = {
  'ui:order': VALID_ORDERED_COMPONENT_KEYS,
  icon: {
    'ui:field': 'iconPicker',
  },
  options: {
    classNames: 'field-array',
  },
  sourceConfig: {
    classNames: 'field-array',
  },
};

export { getComponentSchema, componentUiSchema, VALID_ORDERED_COMPONENT_KEYS };
