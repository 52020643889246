import { combineReducers } from 'redux';

import { reducer as ad } from './bundles/ad';
import { reducer as application } from './bundles/application';
import { reducer as auth } from './bundles/auth';
import { reducer as bookmarks } from './bundles/bookmarks';
import { reducer as components } from './bundles/components';
import { reducer as events } from './bundles/events';
import { reducer as files } from './bundles/files';
import { reducer as integrations } from './bundles/integrations';
import { reducer as layouts } from './bundles/layouts';
import { reducer as models } from './bundles/models';
import { reducer as notifications } from './bundles/notifications';
import { reducer as sites } from './bundles/sites';
import { reducer as sources } from './bundles/sources';
import { reducer as statesets } from './bundles/statesets';
import { reducer as systemMessages } from './bundles/systemMessages';
// import { reducer as tags } from './bundles/tags';
import { reducer as tasks } from './bundles/tasks';
import { reducer as trails } from './bundles/trails';
import { reducer as types } from './bundles/types';
import { reducer as uploads } from './bundles/uploads';
import { reducer as userSettings } from './bundles/user-settings';
import { reducer as qrCodes } from './bundles/qrCodes';
import { reducer as variables } from './bundles/variables';
import { reducer as visualizations } from './bundles/visualizations';
import { reducer as tfrTemplates } from './bundles/tfr-templates';
import { reducer as projects } from './bundles/projects';

const reducers = combineReducers({
  ad,
  application,
  auth,
  bookmarks,
  components,
  events,
  files,
  integrations,
  layouts,
  models,
  notifications,
  sites,
  sources,
  statesets,
  systemMessages,

  tasks,
  trails,
  types,
  uploads,
  userSettings,
  qrCodes,
  variables,
  visualizations,
  tfrTemplates,
  projects,
});

export default reducers;
