import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = React.memo(({ fileName: zipFileName, onDrop }) => {

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        accept: 'application/zip',
        onDrop
    });

    return (
        <div className="component-import-step">
            <div
                className="component-import-step__drop-zone"
                {...getRootProps()}
            >
                <input {...getInputProps()} />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {zipFileName ? (
                        <p>{zipFileName}</p>
                    ) : (
                        <p>{'Drop datamodel file here (.zip)'}</p>
                    )}
                </div>
            </div>
        </div>
    );
});

export default FileUpload;
