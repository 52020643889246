import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can, Icon, useAuth, Input } from '@avtjs/react-components';

import InvitationList from './InvitationList';
import Heading from '../../../../Heading';
import AddInvitationModal from './AddInvitationModal';
import PermissionLegend from '../../../../PermissionLegend';
import SimpleModal from '../../../../SimpleModal';
import RoleSelect from '../../../../RoleSelect';
import { DropdownMenu, DropdownTrigger } from '../../../../DropdownMenu';
import { getUiSchema, getTenantAdminInvitationSchema } from '../schema';

import {
  requestMembers,
  isLoadingMembers,
  isLoadingInvitations,
  requestInvitations,
  createInvitations,
  updateInvitation,
  deleteInvitation,
  getInvitations,
  getMembers,
} from '../../../../../bundles/auth';
import { requestUsers, getUsers, getUsersLoading } from '../../../../../bundles/ad';
import { getSites } from '../../../../../bundles/sites';
import { capitalize } from '../../../../../utils';

const InvitationsTab = () => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const loadingMembers = useSelector(isLoadingMembers);
  const invitations = useSelector(getInvitations);
  const loadingInvitations = useSelector(isLoadingInvitations);
  const loadingUsers = useSelector(getUsersLoading);
  const tenantUsers = useSelector(getUsers);
  const sites = useSelector(getSites);

  const orgMembers = useSelector((state) => getMembers(state, `org/${realm}`));

  const [initialized, setInitialized] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [displayAddInvitationModal, setDisplayAddInvitationModal] = useState(false);
  const [displayRoleDescriptions, setDisplayRoleDescriptions] = useState(false);
  const [inviteFormData, setInviteFormData] = useState(null);
  const [createdEmails, setCreatedEmails] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    dispatch(requestUsers());
    dispatch(requestInvitations());
  }, []);

  useEffect(() => {
    if (!loadingMembers) {
      setInitialized(true);
    }
  }, [loadingMembers]);

  const validTenantUsers = useMemo(() => {
    const invitedUsers = invitations.map((invite) => invite.email);
    if (tenantUsers && invitations) {
      return tenantUsers.filter(
        (user) => user.email && user.enabled && !invitedUsers.includes(user.email)
      );
    }
    return [];
  }, [tenantUsers, invitations]);

  const validateFields = (formData) => {
    // TODO: validate email isn't already attached to a member?
    // If user is already assigned to a site, Tenant admin can assign further permissions directly...
    const hasEmail = !!formData?.email?.length;
    let hasRole = false;

    if (hasEmail) {
      Object.values(formData.roles).forEach((roleList) => {
        if (roleList.length) {
          hasRole = true;
        }
      });
    }

    setSubmitDisabled(!hasEmail || !hasRole);
  };

  useEffect(() => {
    validateFields(inviteFormData);
  }, [inviteFormData]);

  useEffect(() => {
    if (!loadingUsers && !initialized) {
      dispatch(requestMembers(undefined, false));
    }
  }, [loadingUsers, initialized]);

  const cleanUpRoles = (formData) => {
    // We need to remove the "No sites available" because otherwise it's added as a site.
    // The "No sites available" option is needed because JSON schema forms require a value on enums, otherwise it breaks.

    const cleanedData = { ...formData };
    Object.keys(cleanedData.roles).forEach((role) => {
      cleanedData.roles[role] = cleanedData.roles[role].filter(
        (item) => item !== 'No sites available'
      );
    });
    return cleanedData;
  };

  const onInviteUser = useCallback(
    (formData) => {
      const cleanedFormData = cleanUpRoles(formData);
      dispatch(createInvitations({ ...cleanedFormData, org: realm }));
      setDisplayAddInvitationModal(false);
      setInviteFormData(null);
    },
    [realm, createdEmails]
  );

  const onEditInvitation = useCallback((invitation) => {
    setInviteFormData(invitation);
  }, []);

  const onUpdateInvitation = useCallback((formData, invitationId) => {
    const cleanedFormData = cleanUpRoles(formData);
    dispatch(updateInvitation(cleanedFormData, invitationId));
    setInviteFormData(null);
  }, []);

  const onChangeInvitation = useCallback((formData) => {
    validateFields(formData);
    setInviteFormData(formData);
  }, []);

  const onCloseInvitationModal = useCallback(() => {
    setDisplayAddInvitationModal(false);
    setInviteFormData(null);
  }, []);

  const onDeleteInvitation = useCallback((inviteId) => dispatch(deleteInvitation(inviteId)), []);

  const onMemberTypeChange = useCallback(
    (memberType, itemData) => {
      const selectedInvite = invitations.find((i) => i.id === itemData.id);
      let prevSiteRole;
      Object.entries(selectedInvite.roles).forEach(([role, siteIds]) => {
        if (siteIds.includes(itemData.siteId)) {
          prevSiteRole = role;
        }
      });
      if (prevSiteRole !== memberType) {
        const siteIndexOfPrevRole = selectedInvite.roles[prevSiteRole].findIndex(
          (siteId) => siteId === itemData.siteId
        );
        const postData = {
          ...selectedInvite,
          roles: {
            ...selectedInvite.roles,
            [prevSiteRole]: [
              ...selectedInvite.roles[prevSiteRole].slice(0, siteIndexOfPrevRole),
              ...selectedInvite.roles[prevSiteRole].slice(siteIndexOfPrevRole + 1),
            ],
            [memberType]: [...(selectedInvite.roles[memberType] || []), itemData.siteId],
          },
        };

        dispatch(updateInvitation(postData, itemData.id));
      }
    },
    [orgMembers, invitations]
  );

  const handleCreatable = useCallback(
    (value) => {
      const emails = value.match(/(?<name>[\w.-]+)@(?<domain>\w+\.\w+)(\.\w+)?/g);

      if (emails) {
        setInviteFormData((prevInviteFormData) => ({
          ...prevInviteFormData,
          email: [...(prevInviteFormData.email || []), ...emails],
        }));
        setCreatedEmails((prevCreateEmail) => [...prevCreateEmail, ...emails]);
      }
    },
    [inviteFormData]
  );

  const validateCreatable = (value) => {
    return !!value.match(/(?<name>[\w.-]+)@(?<domain>\w+\.\w+)(\.\w+)?/g);
  };

  const buildMenuTrigger = useCallback(
    (currentRole) => (
      <DropdownTrigger
        label={currentRole === 'admin' ? 'Site manager' : capitalize(currentRole)}
        icon="abb-down"
      />
    ),
    []
  );

  const buildMenuOptions = useCallback(
    (itemData) =>
      orgMembers
        ? orgMembers.map((m) => ({
            label: m.type,
            value: m.type,
            onSelect: () => onMemberTypeChange(m.type, itemData),
          }))
        : [],
    [orgMembers, onMemberTypeChange]
  );

  const schema = useMemo(() => {
    if (orgMembers && sites) {
      const rolesWithSites = orgMembers.map((member) => {
        const otherRolesSites = inviteFormData
          ? Object.entries(inviteFormData.roles).reduce((acc, [role, siteIds]) => {
              if (role !== member.type) {
                return [...acc, ...siteIds];
              }
              return acc;
            }, [])
          : [];
        const filteredSites = sites.filter((s) => !otherRolesSites.includes(s.id));

        return {
          ...member,
          siteNames: filteredSites.map((s) => s.name),
          siteIds: filteredSites.map((s) => s.id),
        };
      });

      return getTenantAdminInvitationSchema(
        rolesWithSites,
        validTenantUsers,
        createdEmails,
        handleCreatable,
        validateCreatable
      );
    }
    return {};
  }, [orgMembers, sites, inviteFormData, validTenantUsers, createdEmails]);

  const updateUserSchema = useMemo(
    () => ({
      ...schema,
      properties: {
        ...(schema.properties || {}),
        email: {
          ...(schema.properties?.email || []),
          type: 'string',
          readonly: true,
        },
      },
    }),
    [schema]
  );

  const getPermissions = useCallback(
    (item, itemType) => {
      if (!Object.keys(item.roles).length) return null;

      const siteRoles = Object.entries(item.roles).reduce((acc, [role, connectedSites]) => {
        connectedSites.forEach((siteId) => {
          const connectedSite = sites.find((s) => s.id === siteId);
          if (connectedSite) {
            acc.push([
              connectedSite.name,
              role,
              {
                siteId,
                org: connectedSite.org,
                id: item.id,
              },
            ]);
          }
        });
        return acc;
      }, []);

      return siteRoles.map((siteRole, i) => {
        const key = `${siteRole[1]}-${i}`;
        const userContent = [
          '',
          '',
          <div
            className="role-selector"
            key={key}
          >
            <div>{siteRole[0]}</div>
            <DropdownMenu
              className="blocked-item"
              value={siteRole[1]}
              trigger={buildMenuTrigger(siteRole[1])}
              menuOptions={buildMenuOptions(siteRole[2], itemType)}
              styles={{ menu: { width: '10rem' } }}
            />
          </div>,
        ];
        if (itemType === 'invitation') {
          return userContent.slice(1);
        }
        return userContent;
      });
    },
    [sites, buildMenuTrigger, buildMenuOptions]
  );

  return (
    <>
      <Heading
        contentLeft={
          <>
            <Input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search by email"
              className="user-filter"
            />
            <RoleSelect
              id="role-filter"
              value={roleFilter}
              isMulti={false}
              onChange={setRoleFilter}
              realm={realm}
            />
          </>
        }
        contentRight={
          <Can
            permission="members/Write"
            scope={{ org: realm }}
          >
            <Button
              className="role-descriptions"
              onClick={() => setDisplayRoleDescriptions(true)}
              activity="secondary"
              design="text"
              icon={
                <div className="info-icon">
                  <Icon icon="abb-information-circle-1" />
                </div>
              }
            >
              Role descriptions
            </Button>
            <Button
              className="button-create"
              onClick={() => setDisplayAddInvitationModal(true)}
            >
              Invite user
            </Button>
            {displayAddInvitationModal && (
              <AddInvitationModal
                formData={inviteFormData}
                sites={sites}
                invitations={invitations}
                isUpdate={false}
                schema={schema}
                uiSchema={getUiSchema(true)}
                submitDisabled={submitDisabled}
                onSubmit={onInviteUser}
                onChange={onChangeInvitation}
                onCloseModal={onCloseInvitationModal}
              />
            )}
          </Can>
        }
      />
      <InvitationList
        formData={inviteFormData}
        invitations={invitations}
        searchText={searchText}
        roleFilter={roleFilter}
        isLoading={loadingUsers || loadingInvitations}
        schema={updateUserSchema}
        uiSchema={getUiSchema()}
        onEdit={onEditInvitation}
        onUpdate={onUpdateInvitation}
        onChange={onChangeInvitation}
        onClose={onCloseInvitationModal}
        onDelete={onDeleteInvitation}
        getPermissions={getPermissions}
      />

      {displayRoleDescriptions && (
        <SimpleModal
          title="Role descriptions"
          className="role-permissions-modal"
          size="s"
          onClose={() => setDisplayRoleDescriptions(false)}
        >
          <PermissionLegend />
        </SimpleModal>
      )}
    </>
  );
};

export default InvitationsTab;
