import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { getSystemMessages } from '../../services';
import { getActiveSite } from '../../bundles/sites';
import { getProject } from '../../bundles/projects';
import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';

import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';

import TenantAdminSideBar from '../TenantAdminView/components/TenantAdminSideBar';
// import ComponentView from '../SiteAdminView/ComponentView/ComponentView';
import SiteCreateView from '../SiteCreateView';
import Notifications from '../Notifications';

const BaseViewIoTCloud = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);

  const [headerHeight, setHeaderHeight] = useState(0);
  const systemMessages = useSelector(getSystemMessages);
  const [activeTab, setActiveTab] = useState('configuration');

  const params = useParams();
  const { name: projectName } = params;
  const project = useSelector((state) => getProject(state, projectName));

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const getSystemMessagesHeight = () =>
    document.querySelector('.page-header-component')?.offsetHeight || 0;

  const updateHeaderHeight = () => {
    setHeaderHeight(getSystemMessagesHeight());
  };

  useEffect(() => {
    setHeaderHeight(getSystemMessagesHeight());
  }, [systemMessages, headerHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const showSidebar = true;

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (!project) return null;

  return (
    <>
      {showSidebar && (
        <Sidebar
          site={site}
          headerHeight={headerHeight}
        />
      )}

      {showSidebar && isTenantAdminView && <TenantAdminSideBar />}

      <section className={`base-view ${className} ${showSidebar ? '' : 'no-side-margin'}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: project.name,
          }}
          singlePage={true}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          {headerChildren}
          {withConnectivity && !noSiteView && !siteAdminView && (
            <ConnectivityStatus siteId={site.id} />
          )}
          {/* {withRefresh && <PollingRefresh siteId={site.id} />} */}
          <Notifications />

          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div className="panel">
          <div className="panel-header">
            <span className="iot-class">EC</span>
            <div className="panel-header-button">
              <SiteCreateView />
            </div>
          </div>

          <div className="tabs">
            <div
              className={activeTab === 'configuration' ? 'tab active' : 'tab'}
              onClick={() => openTab('configuration')}
            >
              EC Configuration
            </div>
            {/* <div
              className={activeTab === 'signal' ? 'tab active' : 'tab'}
              onClick={() => {
                openTab('signal');
              }}
            >
              EC Signals
            </div> */}
            {/* <div
              className={activeTab === 'typeConfig' ? 'tab active' : 'tab'}
              onClick={() => {
                openTab('typeConfig');
              }}
            >
              EC type definitions
            </div> */}
          </div>
          <div
            id="configuration"
            className={activeTab === 'configuration' ? 'content active' : 'content'}
          >
            <div className="mims-config">
              <div className="mims-config-type">
                <p>EC Environment</p>
                <p>EC Tenant ID</p>
                <p>EC Project ID</p>
                <p>EC Edge ID</p>
                <p>EC Device Twin API</p>
                <p>EC Data Access API</p>
                <p>EC Type Def API</p>
              </div>

              <div className="mims-config-value">
                <p>{project?.decEnvironment}</p>
                <p>{project?.decTenantId}</p>
                <p>{project?.decProjectId}</p>
                <p>{project?.decEdgeId}</p>
                <p>{project?.decDeviceTwinAPI}</p>
                <p>{project?.decDataAccessAPI}</p>
                <p>{project?.decTypeDefAPI}</p>
              </div>
            </div>
          </div>
          {/* <div
            id="signal"
            className={activeTab === 'signal' ? 'content active' : 'content'}
          >
            <ComponentView machModel={project?.machModel} />
          </div> */}
        </div>
      </section>
    </>
  );
};

export default BaseViewIoTCloud;
