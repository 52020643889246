import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { setActiveComponentId } from '../../bundles/application';

import { getSystemMessages } from '../../bundles/systemMessages';
import { getProject } from '../../bundles/projects';
import { getActiveSite } from '../../bundles/sites';

import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';

import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
import TenantAdminSideBar from '../TenantAdminView/components/TenantAdminSideBar';
import ComponentView from '../SiteAdminView/ComponentView/ComponentView';
import SiteCreateView from '../SiteCreateView';
import Notifications from '../Notifications';

const BaseViewMach = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);

  const [headerHeight, setHeaderHeight] = useState(0);
  const systemMessages = useSelector(getSystemMessages);
  const [activeTab, setActiveTab] = useState('configuration');

  const params = useParams();
  const { name: projectName } = params;
  const project = useSelector((state) => getProject(state, projectName));
  console.log(project);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const getSystemMessagesHeight = () =>
    document.querySelector('.page-header-component')?.offsetHeight || 0;

  const updateHeaderHeight = () => {
    setHeaderHeight(getSystemMessagesHeight());
  };

  useEffect(() => {
    setHeaderHeight(getSystemMessagesHeight());
  }, [systemMessages, headerHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const showSidebar = true;

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (project) {
    return (
      <>
        {showSidebar && (
          <Sidebar
            site={site}
            headerHeight={headerHeight}
          />
        )}

        {showSidebar && isTenantAdminView && <TenantAdminSideBar />}

        <section className={`base-view ${className} ${showSidebar ? '' : 'no-side-margin'}`}>
          <PageHeader
            site={!isTenantAdminView && site}
            title={{
              type: 'string',
              value: project.name,
            }}
            singlePage={true}
            isTenantAdminView={isTenantAdminView}
            dispatchResetComponent={onResetComponent}
          >
            {headerChildren}
            {withConnectivity && !noSiteView && !siteAdminView && (
              <ConnectivityStatus siteId={site.id} />
            )}
            <Notifications />

            <HeaderSettings
              site={site}
              noSiteView={noSiteView}
            />
          </PageHeader>

          <div className="panel">
            <div className="panel-header">
              <span className="iot-class">MACH Information Management System</span>
              <div className="panel-header-button">
                <SiteCreateView />
              </div>
            </div>

            <nav className="tabs">
              <div
                className={activeTab === 'configuration' ? 'tab active' : 'tab'}
                onClick={() => openTab('configuration')}
              >
                MIMS Configuration
              </div>
              <div
                className={activeTab === 'signal' ? 'tab active' : 'tab'}
                onClick={() => {
                  openTab('signal');
                }}
              >
                MIMS Signal
              </div>
            </nav>

            <div
              id="configuration"
              className={activeTab === 'configuration' ? 'content active' : 'content'}
            >
              <div className="mims-config">
                <div className="mims-config-type">
                  <p>Id</p>
                  <p>Name</p>
                  <p>MIMS IP</p>
                  <p>Subscription Type</p>
                  <p>Sample Rate</p>
                  <p>MIMS Module Id</p>
                </div>

                <div className="mims-config-value">
                  <p>{project.id}</p>
                  <p>{project.name}</p>
                  <p>{project.mimsIP}</p>
                  <p>{project.subscriptionType}</p>
                  <p>{project.sampleRate}</p>
                  <p>{project.decModuleId}</p>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default BaseViewMach;
