import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can, Icon, useAuth, Input } from '@avtjs/react-components';

import UserList from './UserList';
import Heading from '../../../../Heading';
import PermissionLegend from '../../../../PermissionLegend';
import SimpleModal from '../../../../SimpleModal';
import RoleSelect from '../../../../RoleSelect';
import { DropdownMenu, DropdownTrigger } from '../../../../DropdownMenu';

import {
  requestMembers,
  getTenantUsers,
  isLoadingMembers,
  isLoadingTenantUsers,
  updateMemberAndConnection,
  deleteAllMemberConnections,
  getMembers,
} from '../../../../../bundles/auth';
import { requestUsers, getUsersLoading } from '../../../../../bundles/ad';
import { getSites } from '../../../../../bundles/sites';

const UsersTab = () => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const loadingMembers = useSelector(isLoadingMembers);
  const loadingTenantUsers = useSelector(isLoadingTenantUsers);
  const loadingUsers = useSelector(getUsersLoading);
  const tenantUsers = useSelector(getTenantUsers);
  const sites = useSelector(getSites);

  const orgMembers = useSelector((state) => getMembers(state, `org/${realm}`));

  const [initialized, setInitialized] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [displayRoleDescriptions, setDisplayRoleDescriptions] = useState(false);

  useEffect(() => {
    dispatch(requestUsers());
  }, []);

  useEffect(() => {
    if (!loadingMembers) {
      setInitialized(true);
    }
  }, [loadingMembers]);

  useEffect(() => {
    if (!loadingUsers && !initialized) {
      dispatch(requestMembers(undefined, true));
    }
  }, [loadingUsers, initialized]);

  const onDeleteUser = useCallback((userId) => dispatch(deleteAllMemberConnections(userId)), []);

  const onRoleChange = useCallback(
    (role, user) => {
      const selectedRole = orgMembers.find((member) => member.type === role);
      const postData = {
        scope: { org: user.org, site: user.siteId },
        permissions: selectedRole.permissions,
        type: selectedRole.type,
        objectId: `site/${user.siteId}`,
        oid: user.id,
        templateId: selectedRole.id,
      };
      dispatch(updateMemberAndConnection(postData, true));
    },
    [orgMembers]
  );

  const buildMenuOptions = useCallback(
    (user) =>
      orgMembers
        ? orgMembers.map((m) => ({
            label: m.type,
            value: m.type,
            onSelect: () => onRoleChange(m.type, user),
          }))
        : [],
    [orgMembers, onRoleChange]
  );

  const getPermissions = useCallback(
    (user) => {
      if (!Object.keys(user.roles).length) return null;

      const siteRoles = Object.entries(user.roles).reduce((acc, [role, connectedSites]) => {
        connectedSites.forEach((siteId) => {
          const connectedSite = sites.find((s) => s.id === siteId);
          if (connectedSite) {
            acc.push([
              connectedSite.name,
              role,
              {
                siteId,
                org: connectedSite.org,
                id: user.id,
              },
            ]);
          }
        });
        return acc;
      }, []);

      return siteRoles.map((siteRole, i) => {
        const key = `${siteRole[1]}-${i}`;
        return [
          '',
          '',
          <div
            className="role-selector"
            key={key}
          >
            <div>{siteRole[0]}</div>
            <DropdownMenu
              className="blocked-item"
              value={siteRole[1]}
              trigger={
                <DropdownTrigger
                  label={siteRole[1]}
                  icon="abb-down"
                />
              }
              menuOptions={buildMenuOptions(siteRole[2])}
              styles={{ menu: { width: '10rem' } }}
            />
          </div>,
        ];
      });
    },
    [sites, buildMenuOptions]
  );

  return (
    <>
      <Heading
        contentLeft={
          <>
            <Input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search by name or email"
              className="user-filter"
            />
            <RoleSelect
              id="role-filter"
              value={roleFilter}
              isMulti={false}
              onChange={setRoleFilter}
              realm={realm}
            />
          </>
        }
        contentRight={
          <Can
            permission="members/Write"
            scope={{ org: realm }}
          >
            <Button
              className="role-descriptions"
              onClick={() => setDisplayRoleDescriptions(true)}
              activity="secondary"
              design="text"
              icon={
                <div className="info-icon">
                  <Icon icon="abb-information-circle-1" />
                </div>
              }
            >
              Role descriptions
            </Button>
          </Can>
        }
      />

      <UserList
        tenantUsers={tenantUsers}
        searchText={searchText}
        roleFilter={roleFilter}
        isLoading={loadingUsers || loadingTenantUsers}
        onDelete={onDeleteUser}
        getPermissions={getPermissions}
      />

      {displayRoleDescriptions && (
        <SimpleModal
          title="Role descriptions"
          className="role-permissions-modal"
          size="s"
          onClose={() => setDisplayRoleDescriptions(false)}
        >
          <PermissionLegend />
        </SimpleModal>
      )}
    </>
  );
};

export default UsersTab;
