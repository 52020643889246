import { Button } from '@avtjs/react-components';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import SimpleModal from '../../../SimpleModal';

const SitesImport = ({ onCloseModal, onSubmit }) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const dispatch = useDispatch();

  const onDrop = ([file]) => {
    setFileToUpload(file);
  };

  const { getInputProps, open } = useDropzone({
    accept: '.xlsx',
    noClick: true,
    onDrop,
  });

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Upload new config"
    >
      <div className="import-sites-modal">
        <p>Import new config from Excel file</p>
        <div className="row">
          <Button onClick={open}> Open </Button>
          <input {...getInputProps()} />
          {fileToUpload ? <p> {fileToUpload.name}</p> : <p>Choose file ...</p>}
        </div>

        <div
          style={{ marginTop: 45, justifyContent: 'space-between', display: 'flex' }}
          className="row"
        >
          <Button
            activity="secondary"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(fileToUpload)}
            disabled={!fileToUpload}
          >
            Apply
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
};

export default SitesImport;
