/* eslint-disable default-param-last */
import { call, put, takeLatest, takeEvery, select, debounce, all } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import {
  importSource as importSourceApi,
  deleteSource as deleteSourceApi,
  updateSource as updateSourceApi,
  createSource as createSourceApi,
  getSources as getSourcesApi,
  getSourceTypes as getSourceTypesApi,
  getSourceHealth as getSourceHealthApi,
  getVariable as getVariableApi,
  getVariables as getVariablesApi,
  createVariable as createVariableApi,
  updateVariable as updateVariableApi,
  deleteVariable as deleteVariableApi,
  uploadAOExcel as uploadAOExcelAPI,
  checkConnectivity as checkConnectivityApi,
} from '../services';
import { getSite } from './sites';
import {
  REFRESH_VALUES,
  CLEAR_SITE_DATA,
  setPollingActive,
  setPollingActiveDone,
} from './application';
import getNotification from './notification-defaults';
import { displayNotification, checkOnline } from './notifications';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_SOURCES = 'dt/sources/REQUEST_SOURCES';
const RECEIVE_SOURCES = 'dt/sources/RECEIVE_SOURCES';

const DELETE_SOURCE = 'dt/sources/DELETE_SOURCE';
const UPDATE_SOURCE = 'dt/sources/UPDATE_SOURCE';
const CREATE_SOURCE = 'dt/sources/CREATE_SOURCE';
const IMPORT_SOURCE = 'dt/sources/IMPORT_SOURCE';

const RESET_SOURCES = 'dt/sources/RESET_SOURCES';

const RECEIVE_SYNC_HEALTH = 'dt/sources/RECEIVE_SYNC_HEALTH';

const REQUEST_CONNECTIVITY = 'dt/sources/REQUEST_CONNECTIVITY';
const RECEIVE_CONNECTIVITY = 'dt/sources/RECEIVE_CONNECTIVITY';

const REQUEST_EXTENDED_SITE_VARIABLES = 'dt/sources/REQUEST_EXTENDED_SITE_VARIABLES';
const RECEIVE_VARIABLE = 'dt/sources/RECEIVE_VARIABLE';
const RECEIVE_EXTENDED_VARIABLE = 'dt/sources/RECEIVE_EXTENDED_VARIABLE';
const REMOVE_VARIABLE = 'dt/sources/REMOVE_VARIABLE';
const RECEIVE_EXTENDED_SITE_VARIABLES = 'dt/sources/RECEIVE_EXTENDED_SITE_VARIABLES';
const CREATE_SOURCE_VARIABLE = 'dt/components/CREATE_SOURCE_VARIABLE';
const UPDATE_SOURCE_VARIABLE = 'dt/components/UPDATE_SOURCE_VARIABLE';
const DELETE_SOURCE_VARIABLE = 'dt/components/DELETE_SOURCE_VARIABLE';

const UPLOAD_AO_EXCEL = 'dt/components/UPLOAD_AO_EXCEL_SHEET';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestSources = ({ siteId, withVariables = true }) => ({
  type: REQUEST_SOURCES,
  siteId,
  withVariables,
});

export const receiveSources = (sources, sourceTypes, siteVariablesIndex, siteId) => ({
  type: RECEIVE_SOURCES,
  sources,
  sourceTypes,
  siteVariablesIndex,
  siteId,
});

export const receiveSyncStatus = (siteId, syncHealth) => ({
  type: RECEIVE_SYNC_HEALTH,
  siteId,
  syncHealth,
});

export const deleteSource = (siteId, sourceId) => ({
  type: DELETE_SOURCE,
  siteId,
  sourceId,
});

export const updateSource = (siteId, sourceId, data) => ({
  type: UPDATE_SOURCE,
  siteId,
  sourceId,
  data,
});

export const createSource = (data) => ({
  type: CREATE_SOURCE,
  data,
});

export const importSource = (siteId, sourceId, onComplete) => ({
  type: IMPORT_SOURCE,
  siteId,
  sourceId,
  onComplete,
});

export const resetSources = () => ({
  type: RESET_SOURCES,
});

export const requestExtendedSiteVariables = () => ({
  type: REQUEST_EXTENDED_SITE_VARIABLES,
});

export const receiveVariable = (variable) => ({
  type: RECEIVE_VARIABLE,
  variable,
});

export const receiveExtendedVariable = (extendedVariable) => ({
  type: RECEIVE_EXTENDED_VARIABLE,
  extendedVariable,
});

export const removeVariable = (variableId) => ({
  type: REMOVE_VARIABLE,
  variableId,
});

export const receiveExtendedSiteVariables = (extendedSiteVariablesIndex) => ({
  type: RECEIVE_EXTENDED_SITE_VARIABLES,
  extendedSiteVariablesIndex,
  extendedSiteVariablesUpdatedAt: Date.now(),
});

export const requestConnectivity = (siteId) => ({
  type: REQUEST_CONNECTIVITY,
  siteId,
});

export const receieveConnectivity = (sourceConnectivityTree, aggregatedConnectivity) => ({
  type: RECEIVE_CONNECTIVITY,
  sourceConnectivityTree,
  aggregatedConnectivity,
});

export const createVariable = (sourceId, data) => ({
  type: CREATE_SOURCE_VARIABLE,
  sourceId,
  data,
});

export const updateVariable = (sourceId, variableId, data) => ({
  type: UPDATE_SOURCE_VARIABLE,
  sourceId,
  variableId,
  data,
});

export const deleteVariable = (sourceId, variableId) => ({
  type: DELETE_SOURCE_VARIABLE,
  sourceId,
  variableId,
});

export const uploadAOExcel = (sourceId, file, siteId, org, onComplete) => ({
  type: UPLOAD_AO_EXCEL,
  sourceId,
  file,
  siteId,
  org,
  onComplete,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  sources: [],
  sourceTypes: [],
  siteVariablesIndex: {},
  extendedSiteVariablesIndex: {},
  injectableSourceTypes: ['elasticsearch'],
  importableSourceTypes: ['custom', 'dec'],
  syncedSourceTypes: ['dec'],
  siteLoaded: undefined,
  syncHealth: {},
  extendedSiteVariablesUpdatedAt: 0,
  sourceConnectivityTree: [],
  aggregatedConnectivity: {},
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SOURCES: {
      return {
        ...state,
        sources: action.sources,
        sourceTypes: action.sourceTypes,
        siteVariablesIndex: action.siteVariablesIndex || state.siteVariablesIndex,
        siteLoaded: action.siteId,
      };
    }
    case RECEIVE_SYNC_HEALTH: {
      const { syncHealth, siteId } = action;

      return {
        ...state,
        syncHealth: {
          ...state.syncHealth,
          [siteId]: syncHealth,
        },
      };
    }
    case RECEIVE_VARIABLE: {
      return {
        ...state,
        siteVariablesIndex: {
          ...state.siteVariablesIndex,
          [action.variable.id]: action.variable,
        },
      };
    }
    case RECEIVE_EXTENDED_VARIABLE: {
      return {
        ...state,
        extendedSiteVariablesIndex: {
          ...state.extendedSiteVariablesIndex,
          [action.extendedVariable.id]: action.extendedVariable,
        },
      };
    }
    case REMOVE_VARIABLE: {
      const varIndexCopy = { ...state.siteVariablesIndex };
      const extendedVarIndexCopy = { ...state.extendedSiteVariablesIndex };
      delete varIndexCopy[action.variableId];
      delete extendedVarIndexCopy[action.variableId];
      return {
        ...state,
        siteVariablesIndex: varIndexCopy,
        extendedSiteVariablesIndex: extendedVarIndexCopy,
      };
    }
    case RECEIVE_EXTENDED_SITE_VARIABLES: {
      return {
        ...state,
        extendedSiteVariablesIndex: action.extendedSiteVariablesIndex,
        extendedSiteVariablesUpdatedAt: action.extendedSiteVariablesUpdatedAt,
      };
    }
    case RECEIVE_CONNECTIVITY: {
      return {
        ...state,
        sourceConnectivityTree: action.sourceConnectivityTree,
        aggregatedConnectivity: action.aggregatedConnectivity,
      };
    }
    case RESET_SOURCES:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        sources: [],
        sourceTypes: [],
        injectableSourceTypes: ['elasticsearch'],
        importableSourceTypes: ['custom', 'dec'],
        syncedSourceTypes: ['dec'],
        siteLoaded: undefined,
        syncHealth: {},
        siteVariablesIndex: {},
        extendedSiteVariablesIndex: {},
        extendedSiteVariablesUpdatedAt: 0,
        sourceConnectivityTree: [],
        aggregatedConnectivity: {},
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSourcesLoaded = (state) => state.sources.siteLoaded;

export const getSources = (state) => state.sources.sources;

export const getSourceTypes = (state) => state.sources.sourceTypes;

export const getInjectableSourceTypes = (state) => state.sources.injectableSourceTypes;

export const getImportableSourceTypes = (state) => state.sources.importableSourceTypes;

export const getSyncedSources = (state) => state.sources.syncedSourceTypes;

export const getSyncHealth = (state, siteId) => state.sources.syncHealth[siteId];

export const getSiteVariablesIndex = (state) => state.sources.siteVariablesIndex;

export const getExtendedSiteVariablesIndex = (state) => state.sources.extendedSiteVariablesIndex;

export const getSiteVariables = createSelector(getSiteVariablesIndex, (varIndex = {}) =>
  Object.values(varIndex)
);

export const getExtendedSiteVariables = createSelector(
  getExtendedSiteVariablesIndex,
  (varIndex = {}) => Object.values(varIndex)
);

export const getExtendedSiteVariablesUpdatedAt = (state) =>
  state.sources.extendedSiteVariablesUpdatedAt;

export const getSourceConnectivityTree = (state) => state.sources.sourceConnectivityTree;

export const getAggregatedConnectivity = (state) => state.sources.aggregatedConnectivity;

export const getVariableById = createSelector(
  getSiteVariablesIndex,
  (_, varId) => varId,
  (varIndex, varId) => varIndex[varId]
);

export const getLinkedSources = createSelector(getSources, (sources) =>
  sources.filter((s) => s.options?.siteSourceLink)
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestSources({ siteId, withVariables }) {
  try {
    const { values: sources } = yield call(getSourcesApi, siteId);
    const { values: sourceTypes } = yield call(getSourceTypesApi);
    if (withVariables) {
      const variablesArray = yield all(
        sources.map((source) => call(getVariablesApi, source.id, { extended: true }))
      );
      const siteVariables = variablesArray.map((v) => v.values).flat(1);
      const updatedVarIndex = {};
      siteVariables.forEach((v) => {
        updatedVarIndex[v.id] = v;
      });
      yield put(receiveSources(sources, sourceTypes, updatedVarIndex, siteId));
    } else {
      yield put(receiveSources(sources, sourceTypes, null, siteId));
    }
  } catch (e) {
    console.error('Unable to fetch sources: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getSources', 'error')()));
    yield put(receiveSources([], [], {}, siteId));
  }
}

function* doDeleteSource(action) {
  const { sourceId, siteId } = action;

  try {
    yield deleteSourceApi(sourceId);
    yield doRequestSources({ siteId });
    yield put(displayNotification(getNotification('deleteSource', 'success')(sourceId)));
  } catch (e) {
    console.error('Unable to delete source: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteSource', 'error')(sourceId)));
  }
}

function* doCreateSource(action) {
  const { data: source } = action;
  const { options: { import: importVars = {} } = {} } = source;
  let created;
  try {
    created = yield createSourceApi(source);
    yield put(displayNotification(getNotification('createSource', 'success')()));
  } catch (e) {
    console.error('Unable to create source: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createSource', 'error')(e.message)));
  }
  if (created && ((importVars.endpoint && importVars.enabled) || created.type === 'dec')) {
    yield put(importSource(source.site, created.id, () => null));
  } else if (created) {
    yield doRequestSources({ siteId: source.site });
  }
}

function* doImportSourceVariables(action) {
  const {
    siteId,
    sourceId,
    onComplete = () => {
      // do something on onComplete
    },
  } = action;

  try {
    const { added, updated } = yield importSourceApi(sourceId);
    const message = `Imported source signals: added (${added}), updated (${updated})`;
    yield put(displayNotification(getNotification('importSourceVars', 'success')(message)));
  } catch (e) {
    console.error('Unable to import source variables: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('importSourceVars', 'error')(e.message)));
  }
  yield doRequestSources({ siteId });
  yield call(onComplete);
}

function* doUpdateSource(action) {
  const { siteId, sourceId, data } = action;

  try {
    yield updateSourceApi(sourceId, data);
    yield doRequestSources({ siteId });
    yield put(displayNotification(getNotification('updateSource', 'success')(sourceId)));
  } catch (e) {
    console.error('Unable to update source: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateSource', 'error')(sourceId)));
  }
}

function* doGetSourceSyncHealth(action) {
  yield put(setPollingActive('sources'));
  try {
    const site = yield select((state) => getSite(state, action.siteId));
    const sources = yield select(getSources);
    const syncedSources = yield select(getSyncedSources);

    if (site && sources.some((s) => syncedSources.includes(s.type))) {
      const syncStatus = yield call(getSourceHealthApi, site.id, site.org);
      if (syncStatus) {
        yield put(receiveSyncStatus(action.siteId, syncStatus));
      }
    }
  } catch (e) {
    console.error('Unable to fetch source health: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getSourceSyncHealth', 'error')()));
  }
  yield put(setPollingActiveDone('sources'));
}

function* doRequestExtendedVariable(action) {
  const { sourceId, variableId } = action;
  try {
    const extendedVariable = yield call(getVariableApi, sourceId, variableId);
    yield put(receiveExtendedVariable(extendedVariable));
  } catch (e) {
    console.error('Unable to fetch signal: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getVariables', 'error')()));
  }
}

function* doRequestExtendedVariables() {
  try {
    const sources = yield select(getSources);
    const variablesArray = yield all(
      sources.map((source) => call(getVariablesApi, source.id, { extended: true }))
    );
    const extendedVariables = variablesArray.map((v) => v.values).flat(1);
    const updatedVarIndex = {};
    extendedVariables.forEach((v) => {
      updatedVarIndex[v.id] = v;
    });
    yield put(receiveExtendedSiteVariables(updatedVarIndex));
  } catch (e) {
    console.error('Unable to fetch signals: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getVariables', 'error')()));
  }
}

function* doCreateSourceVariable(action) {
  const { sourceId, data } = action;

  try {
    const newVariable = yield createVariableApi(sourceId, data);
    yield put(receiveVariable(newVariable));
    yield call(doRequestExtendedVariable, sourceId, newVariable.id);
    yield put(displayNotification(getNotification('createVariable', 'success')(data.name)));
  } catch (e) {
    console.error('Unable to create signal: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createVariable', 'error')(data.name)));
  }
}

function* doUpdateSourceVariable(action) {
  const { sourceId, variableId, data } = action;

  try {
    const updatedVariable = yield updateVariableApi(sourceId, variableId, data);
    yield put(receiveVariable(updatedVariable));
    yield call(doRequestExtendedVariable, sourceId, variableId);
    yield put(
      displayNotification(getNotification('updateVariable', 'success')(data.name, variableId))
    );
  } catch (e) {
    console.error('Unable to update variable: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(getNotification('updateVariable', 'error')(data.name, variableId))
    );
  }
}

function* doDeleteSourceVariable(action) {
  const { sourceId, variableId } = action;

  try {
    yield deleteVariableApi(sourceId, variableId);
    yield put(removeVariable(variableId));
    yield put(displayNotification(getNotification('deleteVariable', 'success')(variableId)));
  } catch (e) {
    console.error('Unable to delete signal: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteVariable', 'error')(variableId)));
  }
}

function* doUploadAOExcel(action) {
  const { sourceId, siteId, file, org, onComplete } = action;
  try {
    const fd = new FormData();
    fd.append('site', siteId);
    fd.append('file', file);
    fd.append('org', org);
    const response = yield call(uploadAOExcelAPI, sourceId, fd);
    if (response.status === 1)
      yield put(displayNotification(getNotification('uploadAOExcel', 'success')()));
    else yield put(displayNotification(getNotification('uploadAOExcel', 'warning')()));
  } catch (e) {
    console.error('Error in importing the file.', e);
    yield put(displayNotification(getNotification('uploadAOExcel', 'error')()));
  }
  yield onComplete();
}

function* doRequestConnectivity() {
  try {
    const sources = yield select(getSources);
    const connectivity = yield all(sources.map((s) => checkConnectivityApi(s.id)));
    const sourceConnectivityTree = connectivity.filter(
      (sourceConnectivity) => Object.values(sourceConnectivity).length
    );

    const getConnectivityStatus = (sourceTree) => {
      let connected = sourceTree.status;
      if (connected === 0) {
        return connected;
      }

      if (sourceTree.dependencies.length) {
        sourceTree.dependencies.forEach((dep) => {
          if (connected !== 0) {
            connected = getConnectivityStatus(dep);
          }
        });
      }
      return connected;
    };

    const aggregatedConnectivity = sourceConnectivityTree.reduce(
      (acc, source) => ({
        ...acc,
        [source.name]: getConnectivityStatus(source),
      }),
      {}
    );

    yield put(receieveConnectivity(sourceConnectivityTree, aggregatedConnectivity));
  } catch (e) {
    console.error('Unable to get connectivity status: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('connectivity', 'error')()));
  }
}

function* doRefreshSources(action) {
  yield call(doGetSourceSyncHealth, action);
  yield call(doRequestConnectivity);
}

export const sagas = [
  takeLatest(REQUEST_SOURCES, doRequestSources),
  takeEvery(DELETE_SOURCE, doDeleteSource),
  takeLatest(UPDATE_SOURCE, doUpdateSource),
  takeLatest(CREATE_SOURCE, doCreateSource),
  takeLatest(IMPORT_SOURCE, doImportSourceVariables),
  takeEvery(REQUEST_EXTENDED_SITE_VARIABLES, doRequestExtendedVariables),
  takeLatest(CREATE_SOURCE_VARIABLE, doCreateSourceVariable),
  takeLatest(UPDATE_SOURCE_VARIABLE, doUpdateSourceVariable),
  takeLatest(DELETE_SOURCE_VARIABLE, doDeleteSourceVariable),
  takeLatest(UPLOAD_AO_EXCEL, doUploadAOExcel),
  takeLatest(REQUEST_CONNECTIVITY, doRequestConnectivity),
  debounce(500, REFRESH_VALUES, doRefreshSources),
];
