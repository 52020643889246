export async function fetchJSON(url) {
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw response;
    }

    return response.json();
  } catch (err) {
    console.error(err);
    return Promise.reject(Error(`Failed to fetch json, ${url}`));
  }
}

export function getQueryParams() {
  const { search } = window.location;

  return search
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc, keyValue) => {
      const [key, val] = keyValue.split('=');
      const value = decodeURIComponent(val);
      return {
        ...acc,
        [key]: value,
      };
    }, {});
}

export function updateUrlQuery(data) {
  let url = window.location.href;

  if (!url.includes('?')) {
    url = `${url}?`;
  }

  Object.entries(data).forEach(([key, val]) => {
    const match = url.match(`${key}=[^&]*`);
    const value = encodeURIComponent(val);

    if (match) {
      const before = url.slice(0, match.index);
      const after = url.slice(match.index + match[0].length);

      if (typeof val === 'undefined') {
        url = `${before}${after}`.replace(/\?&/, '?').replace(/&&/, '&').replace(/[&?]$/, '');
      } else {
        url = `${before}${key}=${value}${after}`;
      }
    } else if (typeof val !== 'undefined') {
      if (!['?', '&'].includes(url.charAt(url.length - 1))) {
        url = `${url}&`;
      }
      url = `${url}${key}=${value}`;
    }
  });

  const qIndex = url.indexOf('?');
  if ((qIndex && url.length > qIndex + 1) || !qIndex) {
    window.history.pushState(null, '', url);
  }
}

export default {
  fetchJSON,
  getQueryParams,
  updateUrlQuery,
};
