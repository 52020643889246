import 'core-js/stable';

import { createRoot } from 'react-dom/client';
import React from 'react'; // eslint-disable-line no-unused-vars
import '../scss/main.scss';

const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
  any: () =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows(),
};

window.isMobile = isMobile.any();

let configPath;
if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_PREVIEW === false) {
  configPath = `/config.${btoa(Date.now()).replace(/=/g, '')}.json`;
} else {
  configPath = '/config.json';
}

fetch(configPath)
  .then((res) => res.json())
  .then(async (config) => {
    window.config = config;
    // For the sake of satisfying the author of global-cache module:
    // https://github.com/ljharb/global-cache/issues/47#issuecomment-991976187
    window.global = window;
    const App = (await import('./components/App')).default;
    createRoot(document.getElementById('app')).render(<App />);
  });
