import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Spinner, Input } from '@avtjs/react-components';
import CustomSelect from '../../CustomSelect';
import ComponentModal from './components/ComponentModal';
import QrModal from './components/QrModal';
import ListItem from '../../ListItem';
import {
  requestComponents,
  getComponentsLoaded,
  getComponents,
  deleteComponent,
  updateComponent,
  createComponent,
} from '../../../bundles/components';
import { getAllIntegrations } from '../../../bundles/integrations';
import { getActiveSite } from '../../../bundles/sites';
import { requestModels, getAllModels } from '../../../bundles/models';
import { requestSources } from '../../../bundles/sources';
import Heading from '../../Heading';
import prepData from './utils';
import { useClientSize } from '../../../utils';

const ComponentView = ({ machModel }) => {
  const dispatch = useDispatch();
  const [{ width }, clientRef] = useClientSize();
  const { id: siteId, org } = useSelector(getActiveSite);
  const modelsHashmap = useSelector(getAllModels);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const components = useSelector(getComponents);
  const integrations = useSelector(getAllIntegrations);

  const [searchFilter, setSearchFilter] = useState('');
  const [relationFilter, setRelationFilter] = useState('all');
  const [integrationFilter, setIntegrationFilter] = useState('none');
  const [sortBy, setSortBy] = useState({ sortKey: 'name', desc: true });
  const [allSelected, setAllSelected] = useState(false);
  const [setAnySelected] = useState(false);
  const [sortedComps, setSortedComps] = useState([]);

  const [updating, setUpdating] = useState({ show: false, componentId: null });
  const [creating, setCreating] = useState({ show: false });
  const [qrModal, setQrModal] = useState({ show: false });

  useEffect(() => {
    if (siteId) {
      dispatch(requestComponents(siteId));
      dispatch(requestModels(siteId));
      dispatch(requestSources({ siteId, withVariables: false }));
    }
  }, [siteId]);

  const componentsWithSelect = useMemo(
    () => components.map((c) => ({ selected: false, ...c })),
    [components]
  );

  const relationFilteredComponents = useMemo(() => {
    if (componentsWithSelect && relationFilter !== 'all') {
      return componentsWithSelect.filter((c) => {
        switch (relationFilter) {
          case 'integrated-active': {
            return Object.values(c.integrationOptions || {}).some((i) => i.enabled);
          }
          case 'integrated-stale': {
            if (!c.integrationOptions) return false;
            return Object.keys(c.integrationOptions).length === 0;
          }
          case 'models': {
            return c.models.length > 0;
          }
          case 'event-sources': {
            return c.eventSources?.length > 0;
          }
          case 'source': {
            return !!c.source;
          }
          case 'state-signals': {
            return c.variables.length > 0;
          }
          case 'stateset': {
            return !!c.stateset;
          }
          case 'area': {
            return c.type === 'area';
          }
          default: {
            return true;
          }
        }
      });
    }
    return componentsWithSelect;
  }, [componentsWithSelect, relationFilter]);

  const integrationFilteredComponents = useMemo(() => {
    if (relationFilteredComponents && integrationFilter !== 'none') {
      return relationFilteredComponents.filter((c) =>
        Object.keys(c.integrationOptions || {}).includes(integrationFilter)
      );
    }
    return relationFilteredComponents;
  }, [relationFilteredComponents, integrationFilter, integrations]);

  const searchedComps = useMemo(() => {
    if (searchFilter) {
      const filters = searchFilter.trim().split(' ');
      return integrationFilteredComponents.filter(
        (c) =>
          filters.every((f) => c.name.toLowerCase().indexOf(f.toLowerCase()) !== -1) ||
          filters.every(
            (f) =>
              c.referenceDesignation &&
              c.referenceDesignation.toLowerCase().indexOf(f.toLowerCase()) !== -1
          )
      );
    }
    return integrationFilteredComponents;
  }, [integrationFilteredComponents, searchFilter]);

  const nameSort = (values, attr, descending = false, shouldReturn = false) => {
    let nameSorted = [];
    if (!shouldReturn) {
      nameSorted = descending
        ? values.sort((a, b) => a.name.localeCompare(b.name))
        : values.sort((a, b) => b.name.localeCompare(a.name));
    }

    if (attr === 'name') {
      return nameSorted;
    }
    if (attr === 'referenceDesignation') {
      if (shouldReturn) {
        return descending
          ? values.sort((a, b) =>
              (a.referenceDesignation || '').localeCompare(b.referenceDesignation || '')
            )
          : values.sort((a, b) =>
              (b.referenceDesignation || '').localeCompare(a.referenceDesignation || '')
            );
      }
      return nameSort(nameSorted, 'referenceDesignation', descending, true);
    }
    return values;
  };

  useEffect(() => {
    if (!machModel) {
      return null;
    }
    console.log(machModel);
    // if (sortBy.sortKey) {
    //   setSortedComps(() => [...nameSort(searchedComps, sortBy.sortKey, sortBy.desc)]);
    // }
    setSortedComps(() =>
      machModel.computers.$values[0].groups.$values[0].items.$values.map((item) => {
        return {
          id: item.$id,
          selected: false,
          name: item.name,
          referenceDesignation: 'null',
          subscriptionType: item.subscriptionType,

          integrationOptions: 'true',
          source: item.source,
          models: [],
          variables: [],
        };
      })
    );
  }, [sortBy, searchedComps, machModel]);

  const count = sortedComps.length ? `(${sortedComps.length})` : '';

  const relationalFilterOptions = [
    { value: 'all', label: 'All signals' },
    { value: 'integrated-active', label: 'Has active integrations' },
    { value: 'integrated-stale', label: 'Has inactive integrations' },
    { value: 'models', label: 'Has models' },
    { value: 'event-sources', label: 'Has event sources' },
    { value: 'source', label: 'Has a data source' },
    { value: 'state-signals', label: 'Has state signals' },
    { value: 'stateset', label: 'Has an area state' },
    { value: 'area', label: 'Is an area type' },
  ];

  const integrationFilterOptions = [
    { value: 'none', label: 'No integration filter' },
    ...integrations.map((i) => ({ value: i.id, label: i.name })),
  ];

  const handleUpdateComponent = useCallback(
    (formData) => {
      const data = prepData(formData);
      data.variables = data.variables?.filter((v) => v != null);
      dispatch(updateComponent(updating.componentId, siteId, data));
      setUpdating({
        show: false,
        componentId: null,
        component: null,
      });
    },
    [updating]
  );

  const handleCloseUpdating = useCallback(() => {
    setUpdating({
      show: false,
      componentId: null,
      component: null,
    });
  }, []);

  const handleCreateComponent = (formData) => {
    const data = prepData(formData);

    dispatch(createComponent(siteId, org, data));
    setCreating({ show: false });
  };

  const handleCloseCreating = useCallback(() => setCreating({ show: false }), []);
  const handleCloseQR = useCallback(() => setQrModal({ show: false }), []);

  const onDeleteComponent = useCallback(
    (componentId) => dispatch(deleteComponent(componentId, siteId)),
    [siteId]
  );

  const handleSort = (sortKey) => {
    const desc = sortBy.sortKey === sortKey ? !sortBy.desc : true;
    setSortBy(() => ({ sortKey, desc }));
  };

  // const colWidths =
  //   width < 1700 ? [5, 15, 10, 20, 10, 10, 10, 10, 10, 7] : [5, 15, 10, 20, 10, 10, 10, 10, 10, 5];
  const colWidths = [5, 15, 10, 20, 10, 10, 10, 10, 10, 7];

  const headers = [
    { label: '' },
    { label: 'Computer' },
    { label: 'Group' },
    { label: 'Item' },
    { label: 'DataType' },
    { label: 'Subscription Type' },
    { label: 'Sample Rate (ms)' },
    { label: 'MIMS Mapping' },
    { label: 'MIMS Property' },
    { label: 'Actions' },
  ];

  const headerColumns = headers.map((h) => {
    const onHeaderClick = h.sortKey ? () => handleSort(h.sortKey) : undefined;
    if (!h.sortKey && !h.icon) {
      return <div className="ellipsed-text">{h.label}</div>;
    }

    let icon;
    if (h.sortKey) {
      const sortIcon =
        sortBy.sortKey === h.sortKey && !sortBy.desc ? 'abb-caret-up' : 'abb-caret-down';
      icon = <Icon icon={sortIcon} />;
    }

    let headerTextOrIcon = h.label;
    if (!h.sortKey && h.icon && width < 1700) {
      headerTextOrIcon = (
        <Icon
          size={'s'}
          icon={h.icon}
        />
      );
    }

    const buttonClass = !h.sortKey ? 'button-header' : '';

    return (
      <Button
        onClick={onHeaderClick}
        icon={icon}
        iconPosition="right"
        activity="secondary"
        className={buttonClass}
        tooltip={h.icon && h.label}
      >
        {headerTextOrIcon}
      </Button>
    );
  });

  const toggleAllSelected = () => {
    if (allSelected) {
      setSortedComps(sortedComps.map((component) => ({ ...component, selected: false })));
      setAnySelected(false);
    } else {
      setSortedComps(sortedComps.map((component) => ({ ...component, selected: true })));
      setAnySelected(true);
    }
    setAllSelected(!allSelected);
  };
  const toggleSelect = (id) => {
    const comps = sortedComps.map((component) =>
      component.id === id ? { ...component, selected: !component.selected } : component
    );
    setSortedComps(comps);
    setAnySelected(comps.some((component) => component.selected === true));
  };

  const getListItemColumns = (component) => [
    <div
      className="ellipsed-text"
      onClick={() => {
        toggleSelect(component.id);
      }}
    >
      <Icon
        className={`select-all ${component.selected ? 'checked' : ''}`}
        icon={`${component.selected ? 'check-box' : 'check-box-outline-blank'}`}
        size="s"
      />
    </div>,
    <div>{component.name}</div>,
    <div>{component.referenceDesignation}</div>,
    <div>{component.subscriptionType || 'Aran '}</div>,
    <div>{component.source ? 1 : 'Aran'}</div>,
    <div>{component.eventSources?.length || 'Aran'}</div>,
    <div>{component.models.length || 'Aran'}</div>,
    <div>{component.variables.length || 'Aran'}</div>,
    <div>{component.stateset ? 1 : 'Aran'}</div>,
  ];

  const componentList = useMemo(() => {
    // if (!componentsLoaded) {
    //   return (
    //     <div className="loading-container">
    //       <Spinner
    //         size="m"
    //         className="spinner"
    //       />
    //     </div>
    //   );
    // }
    return sortedComps.map((c, i) => (
      <ListItem
        key={c.id}
        itemIndex={i}
        entity={`Component (${c.referenceDesignation || '<referenceDesignation>'})`}
        item={c}
        columns={getListItemColumns(c)}
        columnWidths={{ type: 'rem', widths: colWidths }}
        customEdit
        onEdit={() => setUpdating(() => ({ show: true, componentId: c.id }))}
        onDelete={() => onDeleteComponent(c.id)}
        confirmationDialogTitle="Remove Component"
        confimationDialogBody={
          <>
            <p>
              {'This will also remove all child components, variables, event links and references ' +
                'associated with the component.'}
            </p>
            <p style={{ paddingTop: '1.5rem' }}>
              This is a destructive action and cannot be un-done.
            </p>
          </>
        }
      />
    ));
  }, [sortedComps, componentsLoaded, colWidths]);

  return (
    <>
      <Heading
        contentLeft={
          <div className="components-header">
            {/* <div className="title">Manage Components</div> */}
            <Input
              type="text"
              onChange={(e) => setSearchFilter(e.target.value)}
              value={searchFilter}
              placeholder="Search name or reference"
            />

            <div className="component-filter">
              <CustomSelect
                isMulti={false}
                onChange={(selection) => setRelationFilter(selection)}
                creatable={false}
                value={relationFilter}
                rawOptions={relationalFilterOptions}
                closeMenuOnSelect={true}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>
            {integrations.length > 0 && (
              <div className="component-filter">
                <CustomSelect
                  isMulti={false}
                  onChange={(selection) => setIntegrationFilter(selection)}
                  creatable={false}
                  value={integrationFilter}
                  rawOptions={integrationFilterOptions}
                  closeMenuOnSelect={true}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '100%',
                      height: '36px',
                      minHeight: '36px',
                    }),
                  }}
                />
              </div>
            )}
          </div>
        }
      />
      <div
        ref={clientRef}
        className="manage-components-body custom-thin-scrollbar"
      >
        <p className="components">{`Manage signals ${count}`} </p>
        <div className="list-selection-menu">
          <label
            className="select-all-label"
            onClick={toggleAllSelected}
          >
            <Icon
              className={`select-all ${allSelected ? 'checked' : ''}`}
              icon={`${allSelected ? 'check-box' : 'check-box-outline-blank'}`}
              size="s"
            />
            Select all
          </label>
        </div>
        <div className="list-container manage-components-body custom-thin-scrollbar">
          <ListItem
            // hideActionMenu={true}
            withActions={false}
            isHeader
            columns={headerColumns}
            columnWidths={{ type: 'rem', widths: colWidths }}
          />
          {componentList}
        </div>
      </div>
      {qrModal.show && (
        <QrModal
          siteId={siteId}
          onCloseModal={handleCloseQR}
        />
      )}
      {(updating.show || creating.show) && (
        <ComponentModal
          modelsHashmap={modelsHashmap}
          onSubmit={updating.show ? handleUpdateComponent : handleCreateComponent}
          onCloseModal={updating.show ? handleCloseUpdating : handleCloseCreating}
          saveButtonText={updating.show ? 'Update' : 'Create'}
          updateId={updating.componentId}
          parentId={creating.parent}
        />
      )}
    </>
  );
};

export default ComponentView;
